// PopupWindows.js
import React, { useEffect, useState, useContext } from "react";
import { useModal } from "../../ModalManager";
import Managers from "../../Managers";
import Buttons from "../Buttons/Buttons";
import Pages from "../../Pages/Pages";
import { useNavigateContext } from "../../NavigateContext";

function ConfirmationPopup({ title, text }) {
  const [isOpen, setIsOpen] = useState(true); // Add this state to control the modal open/close
  const { closeModal } = useModal();
  const handleClose = (event) => {
    closeModal();
  };

  return (
    <div
      className="modal-container"
      id="m1-o"
      style={{ "--m-background": "transparent" }}
    >
      <div className="modal">
        <h3 className="modal__title">{title}</h3>
        <div>{text}</div>
        <div className="button-holder">
          <Buttons.Flashy text="close" onClick={handleClose} />
          <Buttons.XClose onClick={handleClose} />
        </div>
      </div>
    </div>
  );
}

function CookiePopup({ title, text, uid = "CookiePopup" }) {
  const navigate = useNavigateContext();
  const { closeModal } = useModal();

  if (CookiePopup.sentToReadMore) {
    CookiePopup.sentToReadMore = false;
    closeModal();
    return null;
  }

  const handleAccept = (event) => {
    Managers.Cookie.accept();
    closeModal();
  };

  const handleDeny = (event) => {
    Managers.Cookie.deny();
    closeModal();
  };

  const handleReadMore = (event) => {
    Managers.Cookie.deny();
    CookiePopup.sentToReadMore = true;
    navigate(Pages.names[3]);
    closeModal();
  };

  CookiePopup.sentToReadMore = false;

  return (
    <div
      className="modal-container"
      id="m1-o"
      style={{ "--m-background": "transparent" }}
    >
      <div className="modal">
        <h3 className="modal__title">Cookie and Privacy Policy</h3>
        <div>
          This website uses cookies to ensure you get the best experience on our
          website. <br />
          Not accepting our policies and cookies will break the website.
          <br />
          This is due to the amount of adventures on the website, and the need
          to utilize local storage.
        </div>
        <div className="button-holder">
          <Buttons.Glowing text="Accept" onClick={handleAccept} width="250px" />
        </div>
        <div className="button-holder">
          <Buttons.Normal text="Deny" onClick={handleDeny} />
          <Buttons.Normal text="Read More" onClick={handleReadMore} />
          <Buttons.XClose onClick={handleDeny} />
        </div>
      </div>
    </div>
  );
}

function ExceptionPopup({ title, error, unique = "defaultValue" }) {
  const [isOpen, setIsOpen] = useState(true); // Add this state to control the modal open/close
  const { closeModal } = useModal();
  const handleClose = (event) => {
    closeModal();
  };

  title = title || "Exception!";
  console.error(error);
  const errorMessage = error.code || error.message || "Error message missing.";

  return (
    <div
      className="modal-container"
      id="m1-o"
      style={{ "--m-background": "transparent" }}
    >
      <div className="modal">
        <h3 className="modal__title" style={{ color: "red" }}>
          {title}
        </h3>
        <div>{errorMessage}</div>
        <div className="button-holder">
          <Buttons.Normal text="Close Message" onClick={handleClose} />
          <Buttons.XClose onClick={handleClose} />
        </div>
      </div>
    </div>
  );
}

function FormPopup({ title, form }) {
  const { closeModal } = useModal();
  const handleClose = (event) => {
    closeModal();
  };
  return (
    <div
      className="modal-container"
      id="m1-o"
      style={{ "--m-background": "transparent" }}
    >
      <div className="modal">
        <h3 className="modal__title">{title}</h3>
        {form}
        <Buttons.XClose onClick={handleClose} />
      </div>
    </div>
  );
}

function TablePopup({ title, table }) {
  const { closeModal } = useModal();
  const handleClose = (event) => {
    closeModal();
  };
  return (
    <div
      className="modal-container"
      id="m1-o"
      style={{ "--m-background": "transparent" }}
    >
      <div className="modal">
        <h3 className="modal__title">{title}</h3>
        {table}
        <Buttons.XClose onClick={handleClose} />
      </div>
    </div>
  );
}

const Popups = {
  Confirm: ConfirmationPopup,
  Form: FormPopup,
  Cookie: CookiePopup,
  Table: TablePopup,
  Exception: ExceptionPopup,
};

export default Popups;
