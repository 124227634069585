// AdventureMarkers.js

export const adventureTags = [
  "Exploration",
  "Platforming",
  "Brawl",
  "Perspective",
  "Traps",
  "Themed",
  "Moody",
  "Simple",
  "Complex",
  "Easy",
  "Hard",
  "Hidden Secrets",
  "Speedrun",
  "For 2+ Players",
  "Puzzle",
];

export const adventureComments = [
  "Beautifully Crafted",
  "Smart Puzzles",
  "Piece of Cake",
  "Good Solo",
  "Fun Platforming",
  "Good Fights",
  "Challenging",
  "Good Multiplayer",
];

export const adventureLengths = [
  "Quick",
  "Short",
  "Medium",
  "Long",
  "Epic",
  "Unset",
];

const userDifficulties = [
  "Easy",
  "Medium",
  "Hard",
  "Expert",
  "Insane",
  "Unset",
];

function getDifficultyString(value) {
  if (!value) return userDifficulties[userDifficulties.length - 1];
  value = parseInt(Math.round(parseFloat(value)).toFixed(0));
  return userDifficulties[value];
}

const categories = [
  "Action",
  "RPG",
  "Puzzle",
  "Challenge",
  "Open-World",
  "Unset",
];

const AdventureMarkers = {
  lengths: adventureLengths,
  difficulties: userDifficulties,
  getDifficultyString,
  categories,
};

export default AdventureMarkers;
