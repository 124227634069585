//MasterPage.js

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ModalProvider } from "../../ModalManager";
import { ModalSlideupProvider } from "../../ModalSlideupMenu";
import { useModal } from "../../ModalManager";
import Pages from "../Pages";
import Header from "./Header";
import Footer from "./Footer";
import ContextMaker from "./ContextMaker";
import { NavigateProvider } from "../../NavigateContext";

function MasterPage({ initialized }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSlideupOpen, setIsModalSlideupOpen] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    setIsInitialized(initialized);
  }, [initialized]);

  const setModal = (bool) => {
    console.log("setting modal", bool);
    setIsModalOpen(bool);
  };

  const setSlideupModal = (bool) => {
    console.log("setting modal", bool);
    setIsModalSlideupOpen(bool);
  };

  return (
    <Router>
      <NavigateProvider>
        <ModalProvider setIsModalOpen={setModal}>
          <ModalSlideupProvider setIsModalOpen={setSlideupModal}>
            <ContextMaker />
            <div
              className={`master-page ${
                isModalOpen ? "master-page modal-open" : ""
              } ${!isInitialized ? "is-not-loaded" : ""}`}
            >
              <Header />
              <div id="main-element">
                <Routes>
                  {Pages.names.map((name, index) => {
                    const path = `/${name.toLowerCase()}`;
                    const PageComponent = Pages[name]; // get the correct component
                    return (
                      <Route
                        key={index}
                        path={path}
                        element={<PageComponent />}
                      />
                    );
                  })}
                  {/* Fetch the Home component first before providing it to the element prop */}
                  {(() => {
                    const HomePageComponent = Pages["Home"];
                    return <Route path="/" element={<HomePageComponent />} />;
                  })()}
                </Routes>
              </div>
              <Footer />
            </div>
          </ModalSlideupProvider>
        </ModalProvider>
      </NavigateProvider>
    </Router>
  );
}

export default MasterPage;
