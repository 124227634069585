import React, { useState } from "react";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import Managers from "../Managers.js";
import Popups from "../Components/Popups/Popups.js";
import Forms from "../Components/Forms/Forms.js";
import Buttons from "../Components/Buttons/Buttons";

export default function Register() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const register = async (event) => {
    event.preventDefault();

    console.info(
      "Beginning register process >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>",
    );
    if (password !== confirmPassword) {
      Managers.openModal(
        <Popups.Confirm
          title="Password Mismatch"
          text="The passwords you entered do not match."
        />,
      );
      return;
    }

    console.log("Checking if username is available.");
    const isAvailable = await Managers.User.isUsernameAvailable(username);
    console.log("isAvailable:", isAvailable);
    if (!isAvailable) {
      Managers.openModal(
        <Popups.Confirm
          title="Username Taken"
          text="The submitted username is taken."
        />,
      );
      return;
    }

    console.log("Getting auth");
    const auth = getAuth();
    console.log("auth:", auth);
    try {
      console.log("creating userCredential");
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password,
      );

      console.info("Creating emailRef");
      const emailData = {
        email,
        username,
        date: getDate(),
      };

      const emailRef = await Managers.Firebase.createDocument(
        "emails",
        emailData,
      );

      console.info("Updating display name");
      await updateProfile(userCredential.user, { displayName: username });
      await Managers.User.createUserProfile(username, emailRef, userCredential);

      console.info("User created - Closing registration modal");
      Managers.closeModal();

      console.info("Opening registration confirmation modal.");
      Managers.closeModal();
      Managers.openModal(
        <Popups.Confirm
          title="Registration Successful"
          text="You are now able to begin your journey as an adventurer in Wonderbox Adventures!"
        />,
      );

      // You may want to do something here, like navigate to the logged in page
    } catch (error) {
      console.info("Opening registration failure modal.");
      Managers.openModal(
        <Popups.Confirm title="Registration Failed" text={error.message} />,
      );
      console.error(error);
    }
  };

  const recoverPassword = () => {
    Managers.closeModal();
    Managers.openModal(<Managers.Password.Recover />);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const formData = [
    {
      label: "Username",
      type: "text",
      name: "username",
      className: "submission-inputs",
      handleChange: handleUsernameChange,
      value: username,
      required: true,
    },
    {
      label: "Email",
      type: "email",
      name: "email",
      className: "submission-inputs",
      handleChange: handleEmailChange,
      value: email,
      required: true,
    },
    {
      label: "Password",
      type: "password",
      name: "password",
      className: "submission-inputs",
      handleChange: handlePasswordChange,
      value: password,
      required: true,
    },
    {
      label: "Confirm Password",
      type: "password",
      name: "confirmPassword",
      className: "submission-inputs",
      handleChange: handleConfirmPasswordChange,
      value: confirmPassword,
      required: true,
    },
  ];

  const switchToLogin = () => {
    Managers.closeModal();
    Managers.openModal(<Managers.Password.Login />);
  };

  return (
    <Popups.Form
      title="Register"
      form={
        <div>
          <Forms.Base
            formData={formData}
            handleSubmit={register}
            submitText="Register"
          />
          <div className="button-column">
            <br />
            <Buttons.Flashy
              text="Have an Account?"
              onClick={() => switchToLogin()}
            />
            <br />
          </div>
          <div className="forgot-password-link">
            <a href="#" onClick={recoverPassword}>
              Forgot your password?
            </a>
          </div>
        </div>
      }
    />
  );
}
