import React, { useState } from "react";

// options can be a mixed array of objects and strings
const Select = ({ selector = "Select", options, onChange }) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);

    if (onChange) onChange(event);

    const optionFunction = optionsFunctionMap[event.target.value];
    if (optionFunction) optionFunction(event);
  };

  // Create an object to map values to their associated functions
  // if options is an array of objects
  const optionsFunctionMap = options.reduce((map, option) => {
    // check if option is object and has a function
    if (typeof option === "object" && typeof option.function === "function") {
      return {
        ...map,
        [option.name]: option.function,
      };
    }
    return map;
  }, {});

  return (
    <div className="input-select">
      <select value={value} onChange={handleChange}>
        <option value="">{selector}</option>
        {options.map((option, index) => (
          <option
            key={index}
            value={typeof option === "object" ? option.name : option}
          >
            {typeof option === "object" ? option.name : option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
