import React, { useEffect, useState } from "react";
import { useModal } from "../ModalManager";
import Managers from "../Managers";
import Forms from "../Components/Forms/Forms";
import Popups from "../Components/Popups/Popups";

export default function UserDescriptionUpdate({ currentProfile }) {
  const [newDescription, setNewDescription] = useState("");
  const { openModal, closeModal } = useModal();

  const handleChange = (value) => {
    if (newDescription.length < 255) {
      setNewDescription(value);
    }
  };
  useEffect(() => setNewDescription(currentProfile?.description), []);

  const updateUserDescription = async (event) => {
    console.log("Updating new description", newDescription);
    event.preventDefault();
    if (newDescription.length > 255) {
      return;
    }

    if (Managers.Firebase.currentUser) {
      try {
        await Managers.Firebase.setField(
          "users",
          Managers.Firebase.currentUser.displayName,
          "description",
          newDescription
        );
        closeModal();
        console.log("User description updated successfully");
        Managers.User.refreshLocalUserProfile(Managers.Firebase.currentUser);
      } catch (error) {
        alert("Error updating user description: ", error);
      }
    } else {
      alert("No user is signed in");
    }
  };
  const formData = [
    {
      label: "Description",
      type: "textarea",
      name: "newDescription",
      className: "submission-inputs comment-text-area",
      handleChange: (e) => handleChange(e.target.value),
      value: newDescription,
      required: true,
    },
  ];
  return (
    <Popups.Form
      title="Change Description"
      form={
        <Forms.Base
          formData={formData}
          handleSubmit={updateUserDescription}
          submitText="Save"
          layout="single"
        />
      }
    />
  );
}
