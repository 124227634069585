// AdventureManager.js
import Dates from "../HelperFunctions/DateFunctions";
import Managers from "../Managers";
import AdventureMarkers from "./AdventureMarkers";

async function createAdventure(adventureData) {
  // Public User Updated Data
  adventureData.reviews = [];
  adventureData.difficulty = 3;
  adventureData.description = `An adventure! More possibilities for descriptions will be added later!`;
  adventureData.comments = [];
  adventureData.category = "Unset";

  // Hidden Data
  adventureData.userRatings = [{ user: "", rating: 3 }];
  adventureData.userDifficulties = [{ user: "", rating: 3 }];
  adventureData.issues = [];
  adventureData.userReports = [];
  adventureData.submissionLog = {
    user: Managers.Firebase.currentUser.displayName || "Guest",
    date: Dates.getDate(),
  };
  adventureData.updateLogs = [];

  const docId = await Managers.Firebase.createDocument(
    "adventures",
    adventureData,
  );

  console.log("Adventure Creation Completed 100%");
  return docId;
}

async function destroyAdventure(adventure, creatorname) {
  console.log("destroyAdventure");
  console.log("Creator", creatorname);
  adventure = isAdventureStringOrObject(adventure);

  await Managers.User.removeAdventureFromCreator(creatorname, adventure);
  console.log("Deleteing: " + adventure);
  await Managers.Firebase.deleteDocument("adventures", adventure);
  console.log("Adventure Annihilation Accomplished!");
}

// #########################################################################################
// #########################################################################################
// Corrections
// #########################################################################################
// #########################################################################################

// Will check if the adventure is a string or an object.
// If it is an object, it will be changed to the adventure's firebaseId and returned.
function isAdventureStringOrObject(adventure) {
  if (typeof adventure === "object") {
    return adventure.firebaseId;
  }
  return adventure;
}

// #########################################################################################
// #########################################################################################
// Database Storage
// #########################################################################################
// #########################################################################################

// Is called when an adventure in the firebase database is changed, added or deleted.
async function onAdventureChanged(change, changeType, adventure) {
  if (adventure.isInitial) {
    if (!Managers.Adventure.adventures.includes(adventure)) {
      setLocalAdventureProperties(adventure);
      Managers.Adventure.adventures.push(adventure);
    }
  } else {
    setLocalAdventureProperties(adventure);
    const index = Managers.Adventure.adventures.findIndex(
      (a) => a.firebaseId === adventure.firebaseId,
    ); // Compare adventures by their 'firebaseId' property

    if (index !== -1) {
      console.info(
        "Updating Existing Adventure(old, new)",
        Managers.Adventure.adventures[index],
        adventure,
      );
      // Replace the old version with the new version
      Managers.Adventure.adventures[index] = adventure;
    } else {
      // If not found, push it to the adventures array
      Managers.Adventure.adventures.push(adventure);
    }
  }
}

// Retrieves an adventure from the database.
async function updateLocalAdventure(adventure) {
  adventure = isAdventureStringOrObject(adventure);
  return await Managers.Firebase.getDocument("adventures", adventure);
}

function getParsedAdventures(parsedAdventures) {
  if (!parsedAdventures) return [];
  const foundAdventures = [];

  for (const firebaseId of parsedAdventures) {
    const foundAdventure = Managers.Adventure.adventures.find(
      (a) => a.firebaseId === firebaseId,
    );

    if (foundAdventure) {
      foundAdventures.push(foundAdventure);
    } else {
      console.warn(
        "Adventure with firebaseId from parsedAdventures not found in Managers.Adventure.adventures",
        firebaseId,
      );
    }
  }

  return foundAdventures;
}

// Retrieves an adventure from the database.
async function retrieveAdventure(adventureFirebaseId) {
  adventure = isAdventureStringOrObject(adventureFirebaseId);
  return await Managers.Firebase.getDocument("adventures", adventureFirebaseId);
}

// Updates an adventure in the database.
async function updateAdventure(adventure, updateFields) {
  try {
    adventure = isAdventureStringOrObject(adventure);

    await Managers.Firebase.setFields("adventures", adventure, updateFields);
    return true;
  } catch (error) {
    console.error("Adventure failed to update", adventure, error);
    return false;
  }
}

async function rateAdventure(adventure, value) {
  adventure = isAdventureStringOrObject(adventure);
  const rating = {
    user: Managers.Firebase.currentUser.displayName,
    rating: value,
  };
  console.log("rating", rating);

  // Get the current adventure document
  const adventureData = await getAdventure(adventure);

  if (adventureData) {
    // Find the index of the existing rating by the current user
    const ratingIndex = adventureData.userRatings.findIndex(
      (userRating) => userRating.user === rating.user,
    );

    if (ratingIndex !== -1) {
      // If the user has already rated this adventure, update their existing rating
      await Managers.Firebase.updateItemInArrayField(
        "adventures",
        adventure,
        "userRatings",
        ratingIndex,
        rating,
      );
    } else {
      // If the user hasn't rated this adventure yet, add their rating
      await Managers.Firebase.addItemToArrayField(
        "adventures",
        adventure,
        "userRatings",
        rating,
      );
    }
  } else {
    console.error(`Adventure does not exist.`);
  }
}

async function rateDifficulty(adventure, value) {
  adventure = isAdventureStringOrObject(adventure);
  const difficulty = {
    user: Managers.Firebase.currentUser.displayName,
    difficulty: value,
  };
  console.log("difficulty: ", difficulty);

  // Get the current adventure document
  const adventureData = await getAdventure(adventure);

  if (adventureData) {
    try {
      // Find the index of the existing rating by the current user
      const diffIndex = adventureData.userDifficulties.findIndex(
        (userDifficulty) => userDifficulty.user === difficulty.user,
      );

      if (diffIndex !== -1) {
        // If the user has already rated this adventure, update their existing rating
        await Managers.Firebase.updateItemInArrayField(
          "adventures",
          adventure,
          "userDifficulties",
          diffIndex,
          difficulty,
        );
      } else {
        // If the user hasn't rated this adventure yet, add their rating
        await Managers.Firebase.addItemToArrayField(
          "adventures",
          adventure,
          "userDifficulties",
          difficulty,
        );
      }
    } catch (error) {
      console.error(error);
      console.error(adventureData);
    }
  } else {
    console.error(`Adventure does not exist.`);
  }
}

// #########################################################################################
// #########################################################################################
// Local Storage
// #########################################################################################
// #########################################################################################

async function createLocalAdventureStorage() {
  console.log("Creating Local Adventure Storage");
  const adventures =
    await Managers.Firebase.getAllDocumentsInCollection("adventures");
  console.log("Adventures Fetched: ", adventures);
  await Managers.Cache.save("adventures", adventures);
  console.log("Adventures saved to Local Storage: ", adventures);
}

async function getAdventure(adventure) {
  adventure = isAdventureStringOrObject(adventure);
  for (let i = 0; i < AdventureManager.adventures.length; i++) {
    const localAdventure = AdventureManager.adventures[i];
    if (localAdventure.firebaseId === adventure) {
      return localAdventure;
    }
  }
}

function getAllAdventures() {
  return AdventureManager.adventures || [];
}

// Used for setting local variables, for example setting it so that the values for
// difficulties, which are numbers in the database, will be changed to strings.
function setLocalAdventuresProperties(localAdventures) {
  for (let i = 0; i < localAdventures.length; i++) {
    if (typeof localAdventures[i].difficulty != "string")
      localAdventures[i] = setLocalAdventureProperties(localAdventures[i]);
  }
}

function setLocalAdventureProperties(adventure) {
  setLocalAdventureDifficulty(adventure);
  setLocalRatings(adventure);
  return adventure;
}

function setLocalAdventureDifficulty(adventure) {
  try {
    let totalDifficulty = 0;
    let userDifficulty = 0; // Default value
    adventure.numOfDifficultyVotes = adventure.userDifficulties.length;

    adventure.userDifficulties.forEach((u) => {
      totalDifficulty += u.difficulty;
      // Check if the user is the current user
      if (u.user === Managers.Firebase.currentUser.displayName) {
        // Update the userRating with the rating of the current user
        console.log("matched");
        userDifficulty = u.difficulty;
      }
    });

    // Calculate the average rating
    const averageDifficulty = totalDifficulty / adventure.numOfDifficultyVotes;

    adventure.difficulty =
      AdventureMarkers.getDifficultyString(averageDifficulty);
    adventure.userDifficulty = userDifficulty;
  } catch (error) {
    console.error(error, adventure.firebaseId, adventure);
  }
}

function setLocalRatings(adventure) {
  try {
    let totalRating = 0;
    let userRating = "0"; // Default value
    adventure.numOfRatings = adventure.userRatings.length;

    adventure.userRatings.forEach((u) => {
      totalRating += u.rating;
      // Check if the user is the current user
      if (u.user === Managers.Firebase.currentUser.displayName) {
        // Update the userRating with the rating of the current user
        console.log("matched");
        userRating = u.rating;
      }
    });

    // Calculate the average rating
    const averageRating = totalRating / adventure.numOfRatings;

    adventure.rating = averageRating.toFixed(1);
    adventure.userRating = userRating;
  } catch (error) {
    console.error(error, adventure.firebaseId, adventure);
  }
}

// #########################################################################################
// #########################################################################################
// Export AdventureManager
// #########################################################################################
// #########################################################################################

const AdventureManager = {
  adventures: [],
  getAllAdventures,
  getAdventure,
  createAdventure,
  destroyAdventure,
  onAdventureChanged,
  updateAdventure,
  getParsedAdventures,
  rateAdventure,
  rateDifficulty,
};
// window.adventures = () => AdventureManager.adventures;

export default AdventureManager;

// #########################################################################################
// #########################################################################################
// Mass Manipulation
// #########################################################################################
// #########################################################################################

async function deleteAllAdventuresWithDuplicateLinks() {
  const adventures = await getAllAdventures();
  const linkCount = {};
  const seenLinks = new Set();
  const duplicateAdventures = [];

  // Identify duplicate adventures
  for (const adventure of adventures) {
    if (seenLinks.has(adventure.link)) {
      duplicateAdventures.push(adventure);
    } else {
      seenLinks.add(adventure.link);
    }
  }

  const nameArray = [];
  console.log("Adventures to be deleted: ", duplicateAdventures);
  duplicateAdventures.forEach((adventure) => {
    nameArray.push(adventure.name);
    console.log(adventure.name);
  });

  console.table(nameArray.sort());
  // Delete all duplicate adventures

  for (const duplicateAdventure of duplicateAdventures) {
    await destroyAdventure(
      duplicateAdventure.firebaseId,
      duplicateAdventure.creator,
    );
  }
}

// #########################################################################################
// #########################################################################################
// WINDOW FUNCTIONS ########################################################################
// #########################################################################################
// #########################################################################################

/*window.saveAdventuresToLocalStorage = async () => {
  const adventures = await getAllAdventures();
  await Managers.Cache.save("adventures", adventures);
  console.log("Adventures saved to Local Storage: ", adventures);
};

window.getAdventuresFromLocalStorage = async () => {
  const adventures = await Managers.Cache.load("adventures");
  const storageSize = await Managers.Cache.size();
  console.log("Local Storage Size: ", storageSize);
  console.log("Local Storage Adventures: ", adventures);
};

window.doesLocalStorageRequiresUpdate = async () => {
  const currentFirebaseIds = await Managers.Firebase.getDocument(
    "adventureFirebaseIds",
    "firebaseIds001"
  );
  console.log("currentFirebaseIds: ", currentFirebaseIds);
};*/

window.addNewFieldToAllAdventures = async (newFieldName, newFieldValue) => {
  const adventures = await getAllAdventures(true);
  const collectionName = "adventures";
  const unsuccessfulUpdates = [];
  let adventuresUpdated = 0;
  // Loop through each adventure
  for (let i = 0; i < adventures.length; i++) {
    const adventure = adventures[i];
    if (!adventure.firebaseId) {
      unsuccessfulUpdates.push(adventure);
      continue;
    }
    try {
      // Check if the adventure already has the new field
      if (!adventure.hasOwnProperty(newFieldName)) {
        // If not, set the new field
        await Managers.Firebase.setField(
          collectionName,
          adventure.firebaseId,
          newFieldName,
          newFieldValue,
        );
        adventuresUpdated++;
      }
    } catch (error) {
      console.error(error);
      console.error(adventure);
      console.error(adventure.uId);
      adventure.error = error;
      unsuccessfulUpdates.push(adventure);
      unsuccessfulUpdates.push(error);
    }
  }
  console.info("unsuccessfulUpdates", unsuccessfulUpdates);
  console.info("adventuresUpdated", adventuresUpdated);
};

/*window.updateFieldOfAllAdventures = async (newFieldName, newFieldValue) => {
  const adventures = await getAllAdventures(true);
  const collectionName = "adventures";

  // Loop through each adventure
  for (let i = 0; i < adventures.length; i++) {
    const adventure = adventures[i];

    await Managers.Firebase.setField(
      collectionName,
      adventure.firebaseId,
      newFieldName,
      newFieldValue
    );
  }
};

window.addNewFieldToDocument = async (
  collectionName,
  uid,
  newFieldName,
  newFieldValue
) => {
  await Managers.Firebase.setField(
    collectionName,
    uid,
    newFieldName,
    newFieldValue
  );
};

window.setFullAdventureFirebaseIdsArray = async (
  newFieldName,
  newFieldValue
) => {
  const adventures = await getAllAdventures(true);
  const collectionName = "adventureFirebaseIds";
  const firebaseIdsWithDate = [];
  const date = Dates.getDate();
  // Loop through each adventure
  for (let i = 0; i < adventures.length; i++) {
    const adventure = adventures[i];
    const string = `${adventures[i].firebaseId},${date}`;
    firebaseIdsWithDate.push(string);
  }

  await Managers.Firebase.setField(
    collectionName,
    adventure.firebaseId,
    newFieldName,
    newFieldValue
  );
};

window.updateAllAdventureLinks = async () => {
  const adventures = await getAllAdventures();
  const links = [];
  adventures.forEach((adventure) => {
    links.push(adventure.link.split("/adv/")[1]);
  });
  console.table(links);
  const currentLinks = await Managers.Firebase.getDocument(
    "adventureLinks",
    "links001"
  );
  console.log(currentLinks);
  for (let link of links) {
    if (!currentLinks.links.includes(link)) {
      await Managers.Firebase.addItemToArrayField(
        "adventureLinks",
        "links001",
        "links",
        link
      );
    }
  }
};

window.deleteAllDuplicateAdventures = async () => {
  await deleteAllAdventuresWithDuplicateLinks();
};
*/
