import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  increment,
  arrayUnion,
  arrayRemove,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import firebaseDatabase from "../firebase";
const auth = getAuth();
// window.queries = 0;
window.updatePlaylistsWithFirebaseId = async function () {
  const querySnapshot = await getDocs(
    collection(firebaseDatabase, "playlists"),
  );

  querySnapshot.forEach(async (docSnapshot) => {
    const docRef = doc(firebaseDatabase, "playlists", docSnapshot.id);
    await updateDoc(docRef, {
      firebaseId: docSnapshot.id,
    });
  });
};
// Function to get all documents in a collection
const getAllDocumentsInCollection = async (collectionName) => {
  const col = collection(firebaseDatabase, collectionName);
  const querySnapshot = await getDocs(col);
  const results = [];
  querySnapshot.forEach((document) => {
    //console.log(`getAllDocumentsInCollection Queries: ${/*window.queries++*/}`);
    const data = document.data();
    results.push(data);
  });
  return results;
};

// Function to parse collection and property
const parseCollectionProperty = async (collectionName, propertyName) => {
  const col = collection(firebaseDatabase, collectionName);
  const querySnapshot = await getDocs(col);
  const results = [];
  querySnapshot.forEach((document) => {
    //console.log(`parseCollectionProperty Queries: ${/*window.queries++*/}`);
    const data = document.data();
    if (data.hasOwnProperty(propertyName)) {
      results.push(data[propertyName]);
    }
  });
  return results;
};

const activeListeners = {};

// Function to listen to changes in a collection
const listenToCollectionChanges = async (collectionName, callback) => {
  const col = collection(firebaseDatabase, collectionName);
  //console.log(`listenToCollectionChanges Queries: ${/*window.queries++*/}`);

  // If there's already a listener for this collection, remove it
  if (activeListeners[collectionName]) {
    activeListeners[collectionName]();
    console.log(`Removed existing listener for collection ${collectionName}`);
  }

  let initialRun = true;
  const unsubscribe = onSnapshot(col, (snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (initialRun) {
        // This change is part of the initial data. Add a property to tag it.
        const adventureWithFlag = { ...change.doc.data(), isInitial: true };
        callback(change, change.type, adventureWithFlag);
      } else {
        // This change is a result of an update.
        callback(change, change.type, change.doc.data());
      }
    });

    // Set initialRun to false after the first run
    if (initialRun) {
      initialRun = false;
    }
  });

  // Store the unsubscribe function in the activeListeners object
  activeListeners[collectionName] = unsubscribe;
  console.info(`Added listener for collection ${collectionName}`);

  // Return the unsubscribe function so it can be called to stop listening
  return Promise.resolve(unsubscribe);
};

const listenToDocumentChanges = (collectionName, docId, callback) => {
  const docRef = doc(firebaseDatabase, collectionName, docId);

  // If there's already a listener for this document, remove it
  const listenerId = `${collectionName}_${docId}`;
  if (activeListeners[listenerId]) {
    activeListeners[listenerId]();
    console.log(`Removed existing listener for document ${listenerId}`);
  }

  const unsubscribe = onSnapshot(docRef, (docSnapshot) => {
    callback(docSnapshot.data());
  });

  // Store the unsubscribe function in the activeListeners object
  activeListeners[listenerId] = unsubscribe;
  console.info(`Added listener for document ${listenerId}`);

  // Return the unsubscribe function so it can be called to stop listening
  return unsubscribe;
};

// Function to get a specific document by ID
const getDocument = async (collectionName, docId) => {
  if (!collectionName) {
    console.error("collectionName is not defined:", collectionName);
    return false;
  }
  if (!docId) {
    console.error(
      "collectionName is defined but docId is not defined:",
      collectionName,
      docId,
    );
    return false;
  }
  try {
    const docRef = doc(firebaseDatabase, collectionName, docId);
    const docSnap = await getDoc(docRef);
    //console.log(`getDocument Queries: ${/*window.queries++*/} | collectionName: ${collectionName} | docId: ${docId}`);
    return docSnap.data();
  } catch (err) {
    throw new Error(
      `getDocument in firebase.js failed. collectionName: ${collectionName} | docId: ${docId}`,
    );
  }
};

// Takes the name of a firebase collection and then retrieves the matching ids found in the firebaseIds array.
const getDocuments = async (collectionName, firebaseIds) => {
  try {
    console.log("firebaseIds", firebaseIds);
    // Checking if parameters are valid.
    if (!collectionName) {
      throw new Error(
        `The collectionName parameter is required. collectionName: ${collectionName} | firebaseIds: ${firebaseIds}`,
      );
    }
    if (!Array.isArray(firebaseIds)) {
      throw new Error(
        `The firebaseIds parameter must be an array. collectionName: ${collectionName} | firebaseIds: ${firebaseIds}`,
      );
    }
    if (firebaseIds.length === 0) {
      throw new Error(
        `The firebaseIds array should not be empty. collectionName: ${collectionName} | firebaseIds: ${firebaseIds}`,
      );
    }

    // Initialize the Firestore database and an array to store the retrieved documents
    const db = firebaseDatabase;
    let documents = [];

    // Iterate over the array of IDs
    for (const id of firebaseIds) {
      // Get the document with the current ID from the specified collection
      const docRef = doc(db, collectionName, id);
      const docSnap = await getDoc(docRef);

      // If the document exists, add it to the documents array
      if (docSnap.exists()) {
        documents.push(docSnap.data());
      } else {
        console.warn(
          `Document with ID ${id} not found in collection ${collectionName}.`,
        );
      }
    }
    // Return the array of documents
    return documents;
  } catch (error) {
    // Log any errors that occurred to the console
    console.error(
      `An error occurred while getting documents from collection ${collectionName}: `,
      error,
    );
    throw error;
  }
};

// Function to set a specific field in a document
const setField = async (collectionName, docId, fieldName, value) => {
  try {
    console.log("setField:");
    console.table({ collectionName, docId, fieldName, value });
    const docRef = doc(firebaseDatabase, collectionName, docId);
    //console.log(`setField Queries: ${/*window.queries++*/}`);
    return await updateDoc(docRef, { [fieldName]: value });
  } catch (err) {
    throw new Error(
      `setField in firebase.js failed. collectionName: ${collectionName} | docId: ${docId} | fieldName: ${fieldName} | value: ${value} | error: ${err}`,
    );
  }
};

// Function to set multiple fields in a document
const setFields = async (collectionName, docId, fields) => {
  try {
    console.warn("setFields:");
    console.table({ collectionName, docId });
    console.table(fields);
    const docRef = doc(firebaseDatabase, collectionName, docId);
    //console.log(`setFields Queries: ${/*window.queries++*/}`);
    return await updateDoc(docRef, fields);
  } catch (err) {
    throw new Error(
      `setFields in firebase.js failed. collectionName: ${collectionName} | docId: ${docId} | fields: ${JSON.stringify(
        fields,
      )}`,
    );
  }
};

const incrementField = async (collectionName, docId, fieldName) => {
  const docRef = doc(firebaseDatabase, collectionName, docId);
  console.log(docRef);
  await updateDoc(docRef, {
    [fieldName]: increment(1),
  });
};

// Function to create a new document
const createDocument = async (collectionName, data, docId = null) => {
  try {
    const col = collection(firebaseDatabase, collectionName);

    console.log("data:", data);
    // Check if the new doc is supposed to have a specific uid.
    if (docId) {
      try {
        console.log("Create new doc with ID:", docId);
        //Create a new document in the collection with the specified uid.
        data.firebaseId = docId;
        const docRef = await doc(firebaseDatabase, collectionName, docId);
        console.log("docRef:", docRef);
        await setDoc(docRef, data);
      } catch (error) {
        console.error(
          "Error occurred while creating document with a specific ID:",
          docId,
        );
        console.error("Error details:", error.message);
        // Throw the error again to make it bubble up (if you want to handle it further up)
        throw error;
      }
    } else {
      console.log("col", col);
      console.log("data", data);
      const docRef = await addDoc(col, data);
      docId = docRef.id;
    }
    //console.log(`createDocument Queries: ${/*window.queries++*/}`);
    return docId;
  } catch (error) {
    console.error("Error occurred in createDocument function:");
    console.error("Error details:", error.message);
    // If you want to handle the error outside this function, you can throw it again:
    throw error;
  }
};

const deleteDocument = async (collectionName, docId) => {
  try {
    const docRef = doc(firebaseDatabase, collectionName, docId);
    await deleteDoc(docRef);
    /*console.log(
      `deleteDocument Queries: ${window.queries++} | collectionName: ${collectionName} | docId: ${docId}`
    );*/
  } catch (err) {
    throw new Error(
      `deleteDocument in firebase.js failed. collectionName: ${collectionName} | docId: ${docId}`,
    );
  }
};

// Function to add item to array field of a document
const addItemToArrayField = async (
  collectionName,
  docId,
  arrayFieldName,
  item,
) => {
  // console.log(`addItemToArrayField Queries: ${/*window.queries++*/}`);
  console.log("firebaseDatabase", firebaseDatabase);
  console.log("collectionName", collectionName);
  console.log("docId", docId);
  const docRef = doc(firebaseDatabase, collectionName, docId);
  return await updateDoc(docRef, {
    [arrayFieldName]: arrayUnion(item),
  });
};

// Function to remove an item from an array field of a document
const removeItemFromArrayField = async (
  collectionName,
  docId,
  arrayFieldName,
  item,
) => {
  console.table({ collectionName, docId, arrayFieldName, item });
  //console.log(`removeItemFromArrayField Queries: ${/*window.queries++*/}`);
  const docRef = doc(firebaseDatabase, collectionName, docId);
  const updatedDoc = await updateDoc(docRef, {
    [arrayFieldName]: arrayRemove(item),
  });
  const docSnap = await getDoc(docRef);
  const updatedDocData = docSnap.data();
  console.log("updatedDocData", updatedDocData);
  return updatedDocData;
};

const updateItemInArrayField = async (
  collectionName,
  docId,
  arrayFieldName,
  index,
  newItem,
) => {
  const docRef = doc(firebaseDatabase, collectionName, docId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    if (arrayFieldName in data) {
      if (Array.isArray(data[arrayFieldName])) {
        // Copy the array
        const newArray = [...data[arrayFieldName]];

        // Update the specified index
        newArray[index] = newItem;

        // Write the entire array back
        return await setDoc(
          docRef,
          { [arrayFieldName]: newArray },
          { merge: true },
        );
      } else {
        console.error(`${arrayFieldName} is not an array.`);
      }
    } else {
      console.error(`Field ${arrayFieldName} does not exist in the document.`);
    }
  } else {
    console.error(`Document does not exist.`);
  }
};

// Function to get the size of a document
const getDocumentSize = async (collectionName, docId) => {
  console.log("collectionName", collectionName);
  console.log("docId", docId);
  const docRef = doc(firebaseDatabase, collectionName, docId);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data();
  // console.log(`getDocumentSize Queries: ${/*window.queries++*/}`);
  // Size of document name
  let size = new Blob([docSnap.id]).size;

  // Size of fields
  for (let field in data) {
    const fieldSize = new Blob([field]).size; // size of field name
    const valueSize = new Blob([JSON.stringify(data[field])]).size; // size of field value
    size += fieldSize + valueSize;
  }

  // Additional bytes for system metadata
  size += 32;

  const sizeInKB = size / 1024; // convert size to kilobytes
  const sizeInMB = sizeInKB / 1024; // convert size to megabytes

  console.log(`Size: ${size} b`);
  console.log(`Size: ${sizeInKB} kb`);
  console.log(`Size: ${sizeInMB} Mb`);

  return size;
};

const FirebaseManager = {
  currentUser: null,
  parseCollectionProperty,
  listenToCollectionChanges,
  listenToDocumentChanges,
  getDocument,
  getDocuments,
  getAllDocumentsInCollection,
  getDocumentSize,
  setField,
  setFields,
  incrementField,
  createDocument,
  deleteDocument,
  addItemToArrayField,
  removeItemFromArrayField,
  updateItemInArrayField,
  functionsOnAuthChanged: [],
};

// Authentication
onAuthStateChanged(auth, (user) => {
  if (user) {
    console.log("onAuthStateChanged", user);
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    FirebaseManager.functionsOnAuthChanged.forEach((func) => {
      func(user);
    });
  } else {
    // User is signed out
    FirebaseManager.currentUser = false;
    FirebaseManager.functionsOnAuthChanged.forEach((func) => {
      func(false);
    });
    console.log("User is signed out");
  }
});

// window.setField = setField;

export default FirebaseManager;
