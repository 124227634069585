// ContextMaker.js

import { useState } from "react";
import Managers from "../../Managers";
import { useModal } from "../../ModalManager";
import { useModal as useSlideupModal } from "../../ModalSlideupMenu";
import { useNavigate } from "react-router-dom";
import Pages from "../Pages";

export default function ContextMaker() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(Managers.Firebase.currentUser);
  const [buttons, setButtons] = useState([]);
  const { openModal, closeModal } = useModal();
  const { openSlideupModal, closeSlideupModal } = useSlideupModal();
  const [rerender, setRerender] = useState(0);

  Managers.openModal = openModal;
  Managers.closeModal = closeModal;
  Managers.openSlideupModal = openSlideupModal;
  Managers.closeSlideupModal = closeSlideupModal;
  Pages.navigate = navigate;

  return null;
}
