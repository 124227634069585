import React, { useState } from "react";

const Slider = ({ min, max, onChange }) => {
  const [value, setValue] = useState(50);
  
  const handleChange = (event) => {
    const value = event.target.value;
    setValue(value);
    onChange(value);
  };

  return (
    <div>
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
      />
      <p>Value: {value}</p>
    </div>
  );
};

export default Slider;
