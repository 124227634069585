import React, { useRef, useState } from "react";
import UserProfilePage from "./UserProfile";
import { useModal } from "../../ModalManager";
import { useNavigate } from "react-router-dom";
import Managers from "../../Managers";

function ProfilePage() {
  const [user, setUser] = useState(null);
  const [showingLogin, setShowingLogin] = useState(false);
  const { openModal } = useModal();
  const navigate = useNavigate();

  const hasShownLoginModal = useRef(false);

  return (
    Managers.Firebase.currentUser && (
      <UserProfilePage
        profileUsername={Managers.Firebase.currentUser.displayName}
      />
    )
  );
}

export default ProfilePage;
