// Playlist.js

import Popups from "../Components/Popups/Popups";
import Dates from "../HelperFunctions/DateFunctions";
import Managers from "../Managers";
import FirebaseManager from "../Managers/FirebaseManager";

async function createPlaylist(playlistName, username) {
  username = username || Managers.Firebase.currentUser.displayName;
  if (typeof username != "string")
    username = Managers.Firebase.currentUser.displayName;

  console.log("Creating playlist for " + username);
  if (!username || username.length <= 0) {
    Managers.openModal(
      <Popups.Confirm
        title="Can't Create Playlist"
        text="You must be logged in to create a playlist."
      />,
    );
    return false;
  }

  const playlist = {
    // Public Data
    name: playlistName,
    likes: 0, // Initialize as 0
    creator: username,
    created: Dates.getDate(), // Initialize with current date and time
    adventures: [], // Initialize as empty array
    rating: 3, // Initialize as 3
    reviews: [], // Initialize as empty array
    difficulty: 3, // Initialize as empty string
    description: "A playlist of some insane quality!",
    comments: [], // Initialize as empty array

    // Hidden data
    firebaseId: "", // Initialize as empty string, update when playlist is created in Firebase
    userRatings: [], // Initialize as an empty object
    updateLogs: [], // Initialize as empty array
    userReports: [], // Initialize as empty array
    creationLogs: [], // Initialize as empty array
    officialPlaylist: false, // Initialize as false
  };

  try {
    // Create a document in the 'users' collection with the provided UID
    const playlistId = await Managers.Firebase.createDocument(
      "playlists",
      playlist,
    );

    await Managers.Firebase.addItemToArrayField(
      "users",
      username,
      "playlists",
      playlistId,
    );

    await Managers.Firebase.setField(
      "playlists",
      playlistId,
      "firebaseId",
      playlistId,
    );

    console.log("User playlist created successfully");
    return playlistId;
  } catch (error) {
    console.error("Error creating user playlist: ", error);
  }
}

async function getUserPlaylists(username) {
  console.log("username", username);
  const user = await Managers.Firebase.getDocument("users", username);
  console.log("user", user);
  user.playlists = await Managers.Firebase.getDocuments(
    "playlists",
    user.playlists,
  );

  if (!user.playlists) {
    return [];
  }

  for (let i = 0; i < user.playlists.length; i++) {
    setPlaylistAdventureAmount(user.playlists[i]);
  }
  console.log("user.playlists", user.playlists);

  return user.playlists; // Contains firebase id strings for adventures in its adventures prop.
}

function setPlaylistAdventureAmount(playlist) {
  playlist.adventuresCount = playlist.adventures.length;
}

async function getAdventuresInPlaylist(playlist) {
  const adventures = [];
  console.log("playlist", playlist);
  for (let i = 0; i < playlist.adventures.length; i++) {
    console.log(playlist.adventures[i]);
    const adventure = Managers.Adventure.getAdventure(playlist.adventures[i]);
    adventures.push(adventure);
  }
  return adventures;
}

async function addAdventureToPlaylist(playlist, adventure) {
  if (playlist.adventures.includes(adventure.firebaseId)) return;
  console.error(
    "#########################################################################################",
  );
  console.error("playlist", playlist);
  Managers.Firebase.addItemToArrayField(
    "playlists",
    playlist.firebaseId,
    "adventures",
    adventure.firebaseId || adventure.uid,
  );
  playlist.adventures.push(adventure.firebaseId);
}

async function removeAdventureFromPlaylist(playlist, adventure) {
  if (!playlist.adventures.includes(adventure.firebaseId)) return;
  const updatedPlaylist = await Managers.Firebase.removeItemFromArrayField(
    "playlists",
    playlist.firebaseId,
    "adventures",
    adventure.firebaseId || adventure.uid,
  );
  return updatedPlaylist;
}

const PlaylistManager = {
  createPlaylist,
  getUserPlaylists,
  setPlaylistAdventureAmount,
  addAdventureToPlaylist,
  removeAdventureFromPlaylist,
  getAdventuresInPlaylist,
};

export default PlaylistManager;
