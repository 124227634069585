// TopBanner.js

import { useEffect, useState } from "react";
import Managers from "../../Managers";
import Popups from "../../Components/Popups/Popups";
import Menus from "../../Components/Menus/Menus";
import { useModal } from "../../ModalManager";
import { useNavigate } from "react-router-dom";
import Pages from "../Pages";

export default function TopBanner() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(Managers.Firebase.currentUser);
  const [buttons, setButtons] = useState([]);
  const { openModal } = useModal();
  const [rerender, setRerender] = useState(0);

  const forceRefresh = () => {
    // Update the state to trigger a re-render
    setRerender(rerender + 1);
  };

  if (!Managers.functionsOnAuthChanged?.includes(forceRefresh))
    Managers.Firebase.functionsOnAuthChanged.push(forceRefresh);

  useEffect(() => {
    if (Managers.Cookie.check()) return;
    openModal(<Popups.Cookie unique />);
  }, []); // now useEffect will only re-run when `hasLoaded` changes

  useEffect(() => {
    setCurrentUser(Managers.Firebase.currentUser);
  }, [Managers.Firebase.currentUser]);

  useEffect(() => {
    const profileButton = {
      buttonText: currentUser ? "Profile" : "Login",
      onClick: currentUser
        ? () => setProfilePage()
        : () => {
            openModal(<Managers.Password.Login />);
          },
    };

    const setProfilePage = () => {
      navigate(Pages.names[2]);
    };

    const logoutButton = {
      buttonText: "Logout",
      onClick: () => Managers.Password.logoutUser(),
    };

    const registerButton = {
      buttonText: "Register",
      onClick: () => openModal(<Managers.Password.Register />),
    };

    const patreonButton = {
      buttonText: "Support",
      onClick: () => window.open("https://www.patreon.com/bishiba", "_blank"),
    };

    setButtons([
      patreonButton,
      currentUser ? logoutButton : null,
      currentUser ? null : registerButton,
      profileButton,
      { buttonText: "Menu", onClick: null },
    ]);
  }, [currentUser, navigate]);

  return (
    <div id="top-banner-element">
      <Menus.Sideslide buttons={buttons} />
    </div>
  );
}
