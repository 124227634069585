import Colors from "../../HelperFunctions/Colors";

function FlashyButton({ text, onClick, width, buttonState, submit = false }) {
  if (!width) width = text?.length * 20;
  let buttonClass = "";
  if (buttonState === true) {
    buttonClass = "green-glow";
  } else if (buttonState === false) {
    buttonClass = "red-glow";
  } else {
    buttonClass = "";
  }

  const ButtonContent = () => (
    <div className="flashy-inside" href="#">
      <div className="flashy-button-text">{text}</div>
    </div>
  );

  if (submit) {
    return (
      <div className="flashy-container">
        <button
          type="submit"
          className={`flashy-button ${buttonClass}`}
          style={{ width: width }}
        >
          <ButtonContent />
        </button>
      </div>
    );
  } else {
    return (
      <div className="flashy-container">
        <button
          className={`flashy-button ${buttonClass}`}
          onClick={onClick}
          style={{ width: width }}
        >
          <ButtonContent />
        </button>
      </div>
    );
  }
}

function RoundButton({ text, onClick, color }) {
  let buttonClass = "";
  color = Colors.getColor(color);

  return (
    <div className="round-container">
      <button className={`round-button ${buttonClass}`} onClick={onClick}>
        <div
          className="round-inside"
          href="#"
          style={{ backgroundColor: color }}
        >
          <div className="round-button-text">{text}</div>
        </div>
      </button>
    </div>
  );
}

function SmallRoundButton({ text, onClick, color }) {
  let buttonClass = "";
  color = Colors.getColor(color);

  return (
    <div className="small-round-container">
      <button className={`small-round-button ${buttonClass}`} onClick={onClick}>
        <div
          className="small-round-inside"
          href="#"
          style={{ backgroundColor: color }}
        >
          <div className="small-round-button-text">{text}</div>
        </div>
      </button>
    </div>
  );
}

function SharpButton({ text, onClick, width, buttonState, submit = false }) {
  if (!width) width = text?.length * 20;
  let buttonClass = "";
  if (buttonState === true) {
    buttonClass = "green-glow";
  } else if (buttonState === false) {
    buttonClass = "red-glow";
  } else {
    buttonClass = "";
  }

  return (
    <div className="sharp-container">
      <button
        className={`sharp-button ${buttonClass}`}
        onClick={onClick}
        style={{ width: width }}
      >
        <div className="sharp-inside" href="#">
          <div className="sharp-button-text">{text}</div>
        </div>
      </button>
    </div>
  );
}

function GlowingButton({ text, onClick, width, buttonState, submit = false }) {
  if (!width) width = text?.length * 20;
  let buttonClass = "";
  if (buttonState === true) {
    buttonClass = "green-glow";
  } else if (buttonState === false) {
    buttonClass = "red-glow";
  } else {
    buttonClass = "";
  }

  return (
    <div className="glowy-container">
      <button
        className={`glowy-button ${buttonClass}`}
        onClick={onClick}
        style={{ width: width }}
      >
        <div className="glowy-inside" href="#">
          <div className="glowy-button-text">{text}</div>
        </div>
      </button>
    </div>
  );
}

function NormalButton({ text, onClick, width, buttonState, submit = false }) {
  if (!width) width = text?.length * 20;
  let buttonClass = "";
  if (buttonState === true) {
    buttonClass = "green-glow";
  } else if (buttonState === false) {
    buttonClass = "red-glow";
  } else {
    buttonClass = "";
  }

  return (
    <div className="normal-container">
      <button
        className={`normal-button ${buttonClass}`}
        onClick={onClick}
        style={{ width: width }}
      >
        <div className="normal-inside" href="#">
          <div className="normal-button-text">{text}</div>
        </div>
      </button>
    </div>
  );
}

function XCloseButton({ onClick }) {
  return (
    <div className="close-container">
      <button className={`close-button`} onClick={onClick}>
        <div className="close-inside" href="#">
          <div className="close-button-text">x</div>
        </div>
      </button>
    </div>
  );
}

const Buttons = {
  Flashy: FlashyButton,
  Sharp: SharpButton,
  Glowing: GlowingButton,
  Normal: NormalButton,
  Round: RoundButton,
  SmallRound: SmallRoundButton,
  XClose: XCloseButton,
};

export default Buttons;
