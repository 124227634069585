// Pages.js

import MasterPage from "./MasterPage/MasterPage";
import HomePage from "./HomePage/HomePage";
import AdventuresPage from "./AdventuresPage/AdventuresPage";
import PolicyPage from "./PolicyPage/PolicyPage";
import ProfilePage from "./ProfilePage/ProfilePage";
const names = ["Home", "Adventures", "Profile", "Policy"];

const Pages = {
  Master: MasterPage,
  names,
  Home: HomePage,
  Adventures: AdventuresPage,
  Profile: ProfilePage,
  Policy: PolicyPage,
  current: "",
  navigate: console.error("navigate has not been set to a function. Pages.js"),
};

export default Pages;
