// SubmitAdventure.js

import React, { useContext, useState } from "react";
import { useModal } from "../ModalManager.js";
import Managers from "../Managers";
import Forms from "../Components/Forms/Forms";
import Popups from "../Components/Popups/Popups";

export default function NewPlaylist() {
  const { openModal, closeModal } = useModal();
  const [formType, setFormType] = useState(!formType);
  const [playlistData, setPlaylistData] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {
    console.log(playlistData);
    setPlaylistData({
      ...playlistData,
      [e.target.name]: e.target.value,
    });
  };

  const successfulCreation = () => {
    closeModal();
    openModal(
      <Popups.Confirm
        title="Success!"
        text="The playlist has successfully been added to your playlist library!"
      />
    );
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Handling Create New Adventure");

    // Create Playlist
    await Managers.Playlist.createPlaylist(
      playlistData.name,
      Managers.Firebase.currentUser
    );

    console.log("Successful Playlist Creation");
    successfulCreation();
  };

  const formData = [
    {
      label: "Name",
      type: "text",
      name: "name",
      className: "submission-inputs",
      handleChange: handleChange,
      value: playlistData.name,
      required: true,
    },
    {
      label: "Description",
      type: "text",
      name: "description",
      className: "submission-inputs",
      handleChange: handleChange,
      value: playlistData.description,
      required: false,
    },
  ];
  return (
    <div>
      <Forms.Base
        formData={formData}
        handleSubmit={handleSubmit}
        submitText="Create"
        layout="double"
      />
    </div>
  );
}
