function getDate() {
  // Get current date
  var currentDate = new Date();

  // Extract date components
  var day = String(currentDate.getUTCDate()).padStart(2, "0");
  var month = String(currentDate.getUTCMonth() + 1).padStart(2, "0"); // Month is zero-based
  var year = currentDate.getUTCFullYear();
  var hours = String(currentDate.getUTCHours()).padStart(2, "0");
  var minutes = String(currentDate.getUTCMinutes()).padStart(2, "0");

  // Get the timezone offset (returns the offset in minutes)
  var timezoneOffsetMinutes = currentDate.getTimezoneOffset();

  // Convert the timezone offset to hours and minutes
  var offsetHours = String(
    Math.floor(Math.abs(timezoneOffsetMinutes) / 60)
  ).padStart(2, "0");
  var offsetMinutes = String(Math.abs(timezoneOffsetMinutes) % 60).padStart(
    2,
    "0"
  );

  // Construct the timezone offset string
  var timezoneOffsetString =
    (timezoneOffsetMinutes < 0 ? "+" : "-") + offsetHours + ":" + offsetMinutes;

  // Format the date
  return (
    year +
    "-" +
    month +
    "-" +
    day +
    " " +
    hours +
    ":" +
    minutes +
    " " +
    "UTC" +
    timezoneOffsetString
  );
}

function isFirstDateLesserThanSecond(firstDateStr, secondDateStr = getDate()) {
  // We need to remove the 'UTC+xx:xx' part from the date string in order to be able to parse it correctly
  var parseDate = function (dateStr) {
    return new Date(dateStr.substr(0, dateStr.lastIndexOf(" ")));
  };

  // Parse the date strings into Date objects
  var firstDate = parseDate(firstDateStr);
  var secondDate = parseDate(secondDateStr);

  // Compare the dates
  return firstDate < secondDate;
}

window.getDate = getDate;
const Dates = {
  getDate,
  isFirstDateLesserThanSecond,
};

export default Dates;
