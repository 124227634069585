function getColor(color) {
  switch (color) {
    case "green":
      return Colors.green;
    case "blue":
      return Colors.blue;
      s;
    case "red":
      return Colors.red;
    case "yellow":
      return Colors.yellow;
    case "orange":
      return Colors.orange;
    case "purple":
      return Colors.purple;
    default:
      return Colors.transparent;
  }
}

const Colors = {
  blue: "#3570cc",
  green: "#2ca61a",
  red: "#c42525",
  yellow: "#c4ab25",
  orange: "#ea8e19",
  purple: "#9519ea",
  transparent: "#fff0",

  getColor,
};

export default Colors;
