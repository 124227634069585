import React, { useState } from "react";
import Colors from "../../HelperFunctions/Colors";
import Buttons from "../Buttons/Buttons";

const Slideup = ({ title = "O", buttons }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const openMenuFunc = () => {
    console.log("openMenuFunc");
    setMenuOpen(!menuOpen);
  };

  const handleClick = (buttonOnClick) => {
    console.log("buttonOnClick", buttonOnClick);
    setMenuOpen(false);
    buttonOnClick();
  };

  return (
    <div className="multi-choice-menu">
      <Buttons.SmallRound
        text={title}
        onClick={() => openMenuFunc()}
        color="blue"
      />
      <div
        className={`multi-choice-button-holder ${
          menuOpen ? "multi-choice-button-open" : ""
        }`}
      >
        {buttons.map((button, index) => (
          <Buttons.SmallRound
            key={index}
            text={button.name}
            onClick={() => handleClick(button.onClick)}
          />
        ))}
      </div>
    </div>
  );
};

export default Slideup;
