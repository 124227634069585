import React, { useState } from "react";

const Area = ({ onChange }) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const value = event.target.value;
    setValue(value);
    onChange(value);
  };

  return (
    <div>
      <textarea value={value} onChange={handleChange} />
      <p>Entered Text: {value}</p>
    </div>
  );
};

export default Area;
