// Header.js

import Buttons from "../../Components/Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import Pages from "../Pages";
import { getLongestStringLength } from "../../HelperFunctions/StringFunctions";
import TopBanner from "./TopBanner";

export default function Header() {
  const navigate = useNavigate();
  const longestName = getLongestStringLength(Pages.names);
  const buttonWidth = longestName * 15;
  let pageIndex = 0;
  return (
    <div id="header-element">
      <TopBanner />

      <h1>Wonderbox Adventures</h1>
      <div id="center-window"></div>
      <div className="button-holder">
        <Buttons.Sharp
          text={Pages.names[0]}
          onClick={() => navigate(Pages.names[0])}
          width={buttonWidth}
        />
        <Buttons.Sharp
          text={Pages.names[1]}
          onClick={() => navigate(Pages.names[1])}
          width={buttonWidth}
        />
        <Buttons.Sharp
          text={Pages.names[3]}
          onClick={() => navigate(Pages.names[3])}
          width={buttonWidth}
        />
      </div>
    </div>
  );
}
