import React, { createContext, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Pages from "./Pages/Pages";

// Create the context
export const NavigateContext = createContext();

// Create a provider component
// Create a provider component
export function NavigateProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  Pages.current = location.pathname.substring(1);

  return (
    <NavigateContext.Provider value={navigate}>
      {children}
    </NavigateContext.Provider>
  );
}

// Create a custom hook to use this context
export function useNavigateContext() {
  return useContext(NavigateContext);
}
