import React, { useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Popups from "../Components/Popups/Popups";
import Buttons from "../Components/Buttons/Buttons";
import Forms from "../Components/Forms/Forms";
import { useModal } from "../ModalManager.js";

export default function Recover() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const { closeModal } = useModal();

  const formData = [
    {
      label: "Email",
      type: "email",
      name: "email",
      className: "submission-inputs",
      handleChange: (e) => setEmail(e.target.value),
      value: email,
      required: true,
    },
  ];

  const recover = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      // You might want to notify the user that the email has been sent.
      closeModal();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <Popups.Form
      title="Recover Password"
      form={
        <div>
          <Forms.Base
            formData={formData}
            handleSubmit={recover}
            submitText="Send Password Reset Email"
          />
          {error && <p>{error}</p>}
        </div>
      }
    />
  );
}
