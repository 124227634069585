// Managers.js

import AdventureManager from "./Adventures/AdentureManager";
import CacheManager from "./Managers/CacheManager";
import CookieManager from "./Managers/CookieManager";
import FirebaseManager from "./Managers/FirebaseManager";
import PasswordManager from "./Managers/PasswordManager";
import PlaylistManager from "./Playlists/Playlist";
import UserManager from "./UserManagement/UserManager";

const Managers = {
  initilized: false,
  Firebase: FirebaseManager,
  Adventure: AdventureManager,
  User: UserManager,
  Cache: CacheManager,
  Cookie: CookieManager,
  Password: PasswordManager,
  Playlist: PlaylistManager,

  openModal: (element) => {
    console.warn("Modal not attached to Managers");
  },
  closeModal: () => {
    console.warn("Modal not attached to Managers");
  },
  initialize: async (onInitialized) => {
    console.warn("Initialize not attached to Managers");
  },
  onInitialized: async () => {
    console.warn("onInitialized not attached to Managers");
  },
};

export default Managers;
