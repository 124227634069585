// AdventureTable.js
import React, { useEffect, useState } from "react";
import SubmitAdventure from "./SubmitAdventure/SubmitAdventure";
import { useModal } from "../ModalManager";
import Managers from "../Managers";
import Popups from "../Components/Popups/Popups";
import Buttons from "../Components/Buttons/Buttons";
import AdventureMarkers from "./AdventureMarkers";
import Inputs from "../Components/Inputs/Inputs";
import AdventureItem from "../Components/AdventureItem/AdventureItem";

// window.adventureTableAdventures = [];
export default function AdventureTable({
  showSubmit = false,
  basePlaylist = null,
  onUpdateFunction = null,
}) {
  const { openModal, closeModal } = useModal();
  const propertyNames = ["name", "creator", "length", "rating", "difficulty"];
  const [adventures, setAdventures] = useState(Managers.Adventure.adventures);
  const [filteredAdventures, setFilteredAdventures] = useState(adventures);
  const [filterLength, setFilterLength] = useState(null);
  const [filterDifficulty, setFilterDifficulty] = useState(null);
  const [filterCategory, setFilterCategory] = useState(null);
  const [filterRating, setFilterRating] = useState(null);
  const [filterCreator, setFilterCreator] = useState(null);
  const [filterAdventureName, setFilterAdventureName] = useState(null);
  const [playlist, setPlaylist] = useState(basePlaylist);
  const [sortKey, setSortKey] = useState(null);
  const [sortIndicator, setSortIndicator] = useState({
    name: null,
    creator: null,
    length: null,
    rating: null,
    difficulty: null,
    category: null,
  });

  const [rerender, setRerender] = useState(0);

  const forceRerender = () => {
    // Update the state to trigger a re-render
    setRerender(rerender + 1);
  };

  const adventureListSorting = (headerName) => {
    const newSortKey = headerName.toLowerCase();

    setSortIndicator((prevIndicator) => {
      const newIndicator = { ...prevIndicator };

      if (newSortKey === sortKey) {
        // Toggle between 'asc' and 'desc' for the current sort key
        newIndicator[newSortKey] =
          newIndicator[newSortKey] === "asc" ? "desc" : "asc";
      } else {
        // Set the new sort key to 'asc' and reset all other keys
        for (const key in newIndicator) {
          newIndicator[key] = null;
        }

        newIndicator[newSortKey] = "asc";
      }

      return newIndicator;
    });

    setSortKey(newSortKey);
  };

  useEffect(() => {
    console.error("Playlist changed", playlist);
  }, [playlist]);

  useEffect(() => {
    console.log("Sorting", sortKey);
    if (sortKey) {
      const sortedAdventures = [...filteredAdventures].sort((a, b) => {
        // Handles cases where sortKey is rating (number) or other fields (string)
        const isNumber = typeof a[sortKey] === "number";

        if (isNumber) {
          return sortIndicator[sortKey] === "asc"
            ? a[sortKey] - b[sortKey]
            : b[sortKey] - a[sortKey];
        } else {
          return sortIndicator[sortKey] === "asc"
            ? a[sortKey].localeCompare(b[sortKey])
            : b[sortKey].localeCompare(a[sortKey]);
        }
      });

      setFilteredAdventures(sortedAdventures);
    }
  }, [sortKey, sortIndicator]); // removed filteredAdventures

  useEffect(
    () => {
      console.log("Managers.Adventure.adventures updated?");
      if (playlist) console.log("playlist", playlist);
      playlist
        ? setAdventures(
            Managers.Adventure.getParsedAdventures(playlist.adventures),
          )
        : setAdventures(Managers.Adventure.getAllAdventures());
    },
    [Managers.Adventure.adventures],
    playlist,
  );

  // Filter
  /*
    adventure.creator
    adventure.length
    adventure.rating
    adventure.difficulty
    adventure.category
  */
  useEffect(() => {
    console.log("Filters Changed!");
    let newFilteredAdventures = [...adventures];

    if (filterLength) {
      console.log("Length filtering", newFilteredAdventures);
      newFilteredAdventures = newFilteredAdventures.filter(
        (adventure) => adventure.length === filterLength,
      );
    }

    if (filterDifficulty) {
      console.log("Difficulty filtering", newFilteredAdventures);
      newFilteredAdventures = newFilteredAdventures.filter(
        (adventure) => adventure.difficulty === filterDifficulty,
      );
    }

    if (filterCategory) {
      console.log("Category filtering", newFilteredAdventures);
      newFilteredAdventures = newFilteredAdventures.filter(
        (adventure) => adventure.category === filterCategory,
      );
    }

    if (filterRating) {
      console.log("Rating filtering", newFilteredAdventures);
      const [min, max] = filterRating;
      newFilteredAdventures = newFilteredAdventures.filter(
        (adventure) => adventure.rating >= min && adventure.rating <= max,
      );
    }

    if (filterCreator) {
      console.log("Creator filtering", newFilteredAdventures);
      const creators = filterCreator
        .split(",")
        .map((name) => name.trim().toLowerCase());
      newFilteredAdventures = newFilteredAdventures.filter((adventure) =>
        creators.some((creator) =>
          adventure.creator.toLowerCase().includes(creator),
        ),
      );
    }
    if (filterAdventureName) {
      console.log("Adventure name filtering", newFilteredAdventures);
      const adventureNames = filterAdventureName
        .split(",")
        .map((name) => name.trim().toLowerCase());
      newFilteredAdventures = newFilteredAdventures.filter((adventure) =>
        adventureNames.some((name) =>
          adventure.name.toLowerCase().includes(name),
        ),
      );
    }
    // Sorting (uncomment if needed)
    // newFilteredAdventures.sort((a, b) => a.creator.localeCompare(b.creator));

    setFilteredAdventures(newFilteredAdventures);
  }, [
    filterLength,
    filterDifficulty,
    filterCategory,
    filterRating,
    filterCreator,
    filterAdventureName,
    adventures,
  ]);

  const headers = {
    name: "Name",
    creator: "Creator",
    category: "Category",
    length: "Length",
    rating: "Rating",
    difficulty: "Difficulty",
  };

  return (
    <div className="adventure-table-window">
      {!Managers.Cookie.check() && (
        <div style={{ color: "red" }}>
          To load the adventures, the terms of use needs to be accepted.
        </div>
      )}
      {/* ...rest of the JSX */}
      {showSubmit && (
        <Buttons.Glowing
          text="Submit Adventure"
          onClick={() =>
            openModal(
              <Popups.Form
                title="Submit Adventure"
                form={<SubmitAdventure />}
              />,
            )
          }
        />
      )}
      <div className="text-filter-holder">
        Adventures:{" "}
        <Inputs.Text
          onChange={(event) => setFilterAdventureName(event.target.value)}
        />
      </div>
      <div className="select-filter-holder">
        <Inputs.Select
          selector="Length"
          options={AdventureMarkers.lengths}
          onChange={(event) => setFilterLength(event.target.value)}
        />
        <Inputs.Select
          selector="Difficulty"
          options={AdventureMarkers.difficulties}
          onChange={(event) => setFilterDifficulty(event.target.value)}
        />
        <Inputs.Select
          selector="Category"
          options={AdventureMarkers.categories}
          onChange={(event) => setFilterCategory(event.target.value)}
        />
      </div>
      <Inputs.MinMax
        title="Rating"
        onChange={(value) => setFilterRating([value.min, value.max])}
        minValue={0}
        maxValue={5}
        step={0.1}
      />
      <div className="text-filter-holder">
        Creators:{" "}
        <Inputs.Text
          onChange={(event) => setFilterCreator(event.target.value)}
        />
      </div>
      Showing {filteredAdventures.length} Adventures
      <AdventureItem
        headers={headers}
        onHeaderClick={adventureListSorting}
        sortIndicator={sortIndicator}
      />
      <div className="adventure-table-container">
        {filteredAdventures.map((adventure, index) => (
          <AdventureItem
            key={index}
            adventure={adventure}
            playlist={playlist}
            onUpdateFunction={(updatedPlaylist) => {
              console.log(updatedPlaylist);
              setPlaylist(updatedPlaylist);
            }}
          />
        ))}
      </div>
    </div>
  );
}
