import Managers from "../../Managers";

export default async function verifyLink(
  link,
  invalidLinkMessage,
  linkExistsMessage
) {
  console.info("Verifying URL");
  const isValidURL = isUrlFromDomain(link, "playwonderbox.com", "/adv/");
  console.log("isValidURL", isValidURL);
  if (!isValidURL) {
    invalidLinkMessage();
    return false;
  }

  console.info("Verifying Link");
  const isLinkAvailableBool = await isLinkAvailable(link);
  console.log("isAvailableLink", isLinkAvailableBool);
  if (!isLinkAvailableBool) {
    linkExistsMessage();
    return false;
  }
  console.log("Link Verified");
  return true;
}

function isUrlFromDomain(url, domain, pathnameStart) {
  try {
    const parsedUrl = new URL(url);
    return (
      parsedUrl.hostname === domain &&
      parsedUrl.pathname.startsWith(pathnameStart)
    );
  } catch (err) {
    console.error("Invalid URL", err);
    return false;
  }
}

async function isLinkAvailable(link) {
  link = link.split("/adv/")[1];
  const adv = [];
  const adventureLinksArray =
    await Managers.Firebase.getAllDocumentsInCollection("adventureLinks");
  let adventureLinks = adventureLinksArray.reduce(
    (accumulator, currentDocument) => {
      return accumulator.concat(currentDocument.links);
    },
    []
  );

  console.log("link: ", link);
  console.log("Adventure Links", adventureLinks);
  for (let i = 0; i < adventureLinks.length; i++) {
    console.log(`${adventureLinks[i]} === ${link}`);
    if (adventureLinks[i] === link) {
      return false;
    }
  }
  return true;
}
