// AdventuresPage.js

import React, { useState, useEffect } from "react";
import AdventureMarkers from "../../Adventures/AdventureMarkers";
import AdventureTable from "../../Adventures/AdventureTable";
import Inputs from "../../Components/Inputs/Inputs";
import Managers from "../../Managers";
import AdventureItem from "../../Components/AdventureItem/AdventureItem";

export default function AdventuresPage() {
  //<Inputs.Select options={options} />;
  return (
    
    <div className="adventures-page">{console.log("Adventures Loaded: ")}
      <AdventureTable showSubmit={true} />
    </div>
  );
}
