// UserManager.js
import { getFirestore, doc, getDoc, setDoc } from "firebase/firestore";
import Managers from "../Managers";

export const User = {
  userProfile: {},
  isLoggedIn: false,
};

const cachedUsers = [];

const creators = [];

function isUserCurrentUser(username) {
  try {
    if (Managers.Firebase.currentUser.displayName === username) return true;
  } catch (err) {
    return false;
  }
  return false;
}

// Creates a new user profile.
export const createUserProfile = async (username, email, userCredential) => {
  console.info("emailref:", email);
  const user = {
    // Public Data
    username,
    description: "An adventurer of untold skill!", // Initialize as an empty string
    creator: false,
    playlists: [], // Initialize as an empty array
    activityLog: [], // Initialize as an empty array
    uid: userCredential.user.uid,

    // Private Data
    email,
    following: 0,

    // Hidden data
    activityLogs: [],
  };

  try {
    // Create a document in the 'users' collection with the provided UID
    // !!!!!!!! RETURNS THE ID OF THE DOCUMENT !!!!!!!!!!!!!!!!!
    const newUser = await Managers.Firebase.createDocument(
      "users",
      user,
      username,
    );

    console.log("Adding the username to usernames");
    await Managers.Firebase.addItemToArrayField(
      "usernames",
      "namesArray",
      "array",
      username,
    );

    console.info("User profile created successfully");
  } catch (error) {
    console.error("Error creating user profile: ", error);
  }
  await Managers.Playlist.createPlaylist("Favorites");
};

// #########################################################################################
// #########################################################################################
// Corrections
// #########################################################################################
// #########################################################################################

// Will check if the adventure is a string or an object.
// If it is an object, it will be changed to the adventure's firebaseId and returned.
function isUserStringOrObject(user) {
  if (typeof user === "object") {
    return user.firebaseId || user.username || user.displayName;
  }
  return user;
}

const refreshCurrentUserProfile = (user) => {
  Managers.Firebase.currentUser = user;
  console.log("Managers.Firebase.currentUser", Managers.Firebase.currentUser);
};

export const getUserProfileWithUsername = async (username) => {
  const db = getFirestore();

  try {
    // Retrieve the user's uid from the usernames collection
    const usernameDoc = doc(db, "usernames", username);
    const usernameSnapshot = await getDoc(usernameDoc);

    if (usernameSnapshot.exists()) {
      const uid = usernameSnapshot.data().uid;

      // Get user's profile from the users collection
      const userDoc = doc(db, "users", uid);
      const userProfile = await getDoc(userDoc);

      if (userProfile.exists()) {
        window.viewedProfile = userProfile.data();
        return userProfile.data();
      } else {
        console.log("No such user!");
        return null;
      }
    } else {
      console.log("Username does not exist!");
      return null;
    }
  } catch (error) {
    console.error("Error getting user profile: ", error);
  }
};

async function updateProfile(user, field, value) {
  await Managers.Firebase.setField("users", user.uid, field, value);
}

async function getAllUsernames() {
  const usernames = await Managers.Firebase.getDocument(
    "usernames",
    "namesArray",
  );

  return usernames.array;
}

async function isUsernameAvailable(username) {
  const usernames = await getAllUsernames();
  const lowercaseUsernames = usernames.map((username) =>
    username.toLowerCase(),
  );
  console.info("usernames:", lowercaseUsernames);
  if (lowercaseUsernames.includes(username.toLowerCase())) return false;

  return true;
}

async function getCreator(username) {
  return await Managers.Firebase.getDocument("creators", username);
}

async function getAllCreators() {
  return await Managers.Firebase.getAllDocumentsInCollection("creators");
}

async function getCreatorAdventures(username) {
  const adventureReferences = await getAllCreators().then(
    (creators) =>
      creators.find((creator) => creator.username === username).adventures,
  );

  const adventures = [];

  for (let i = 0; i < adventureReferences.length; i++) {
    adventures.push(await Managers.Adventure.getAdventure(adventureUid));
  }

  return adventures;
}

async function createCreator(username, adventureRef) {
  const data = {
    username: username,
    adventures: [adventureRef],
    followers: 0,
    user: "",
  };
  return await Managers.Firebase.createDocument(
    "creators",
    data,
    data.username,
  );
}

async function addAdventureToCreator(username, adventureFirebaseId) {
  const creator = await getCreator(username);

  const adventures = creator.adventures;
  if (creator.adventures.includes(adventureFirebaseId)) {
    console.log("Creator already assigned this adventure");
    return;
  }

  await Managers.Firebase.addItemToArrayField(
    "creators",
    username,
    "adventures",
    adventureFirebaseId,
  );

  return true;
}

async function removeAdventureFromCreator(username, adventureFirebaseId) {
  const creator = await getCreator(username);

  // Check if creator has the adventure in their adventures list
  if (creator.adventures.includes(adventureFirebaseId)) {
    // If adventure exists in the creator's list, remove it
    await Managers.Firebase.removeItemFromArrayField(
      "creators",
      username,
      "adventures",
      adventureFirebaseId,
    );
    return true;
  }

  // If adventure doesn't exist in the creator's list, return false or throw an error
  return false;
}

async function followUnfollowCreator(creatorName) {
  const creator = await getCreator(creatorName);

  if (Managers.Firebase.currentUser?.isLoggedIn) return;
  const user = await getUserProfile(Managers.Firebase.currentUser.displayName);

  user.following.push(creator);
  creator.followers.push(user);
}

export async function getUserProfile(username) {
  if (!username) {
    console.error("Username is falsy", username);
    return;
  }

  if (typeof username === "object") {
    username = username.username || username.displayName;
  }

  const user = await retrieveUserProfile(username);
  return user;
}

export async function retrieveUserProfile(username) {
  const user = await Managers.Firebase.getDocument("users", username);
  return user;
}

async function initializeUserProfile() {
  await refreshCurrentUserProfile(Managers.Firebase.currentUser.displayName);
}

const UserManager = {
  User,
  cachedUsers,
  refreshCurrentUserProfile,
  getUserProfile,
  createUserProfile,
  getUserProfileWithUsername,
  updateProfile,
  usernames: [],
  getCreator,
  getAllCreators,
  getCreatorAdventures,
  createCreator,
  addAdventureToCreator,
  removeAdventureFromCreator,
  followUnfollowCreator,
  initializeUserProfile,
  isUsernameAvailable,
  isUserStringOrObject,
};

export default UserManager;
