// SubmitAdventure.js

import React, { useContext, useState } from "react";
import { adventureLengths } from "../AdventureMarkers.js";
import verifyLink from "./VerifyLink";
import { useModal } from "../../ModalManager.js";
import Dates from "../../HelperFunctions/DateFunctions.js";
import Forms from "../../Components/Forms/Forms.js";
import Managers from "../../Managers.js";
import Popups from "../../Components/Popups/Popups.js";

export default function SubmitAdventure({ onClose }) {
  const { openModal, closeModal } = useModal();
  const [formType, setFormType] = useState(!formType);
  const [adventureData, setAdventureData] = useState({
    shareLink: "",
    name: "",
    link: "",
    plays: 0,
    likes: 0,
    creator: "",
    length: "",
    created: "!" + Dates.getDate(),
  });

  const handleChange = (e) => {
    console.log(adventureData);
    setAdventureData({
      ...adventureData,
      [e.target.name]: e.target.value,
    });
  };

  const closeForm = () => {
    // Reset form values
    resetForm();
    // Close the form by setting showAdventureForm to false
    setShowAdventureForm(false);
  };

  const resetForm = () => {
    // Reset form values
    setAdventureData({
      shareLink: "",
      name: "",
      link: "",
      creator: "",
      length: "",
      plays: 0,
      likes: 0,
      created: new Date(),
    });
  };

  const invalidLink = () => {
    openModal(
      <Popups.Confirm
        title="Invalid Wonderbox link"
        text="The link provided is not from the correct domain. This is a security feature. If the link you wish to provie should be accurate, please contact Bishiba#7478 @ Discord or through the official Wonderbox Discord."
      />,
    );
    return false;
  };

  const linkNameFail = () => {
    openModal(
      <Popups.Confirm
        title="Link Occupied"
        text="An adventure with this link already exists."
      />,
    );
    return false;
  };

  const nameTaken = () => {
    openModal(
      <Popups.Confirm
        title="Adventure Name Taken"
        text="An adventure with this name already exists."
      />,
    );
    return false;
  };

  const faultyShareLink = () => {
    console.log(adventureData.shareLink);
    openModal(
      <Popups.Confirm
        title="Invalid Share Link"
        text="The submitted text was not in the correct format. Please use the link you get from sharing the adventure from within Wonderbox"
      />,
    );
  };

  const noLengthProvided = () => {
    openModal(
      <Popups.Confirm
        title="Length Required"
        text="The length of the adventure must be provided in order to submit an adventure."
      />,
    );
  };

  const creatorExists = async (creator, adventureFirebaseId) => {
    await Managers.User.addAdventureToCreator(creator, adventureFirebaseId);
  };

  const creatorDoesNotExist = async (creator, adventureFirebaseId) => {
    console.log(adventureFirebaseId);
    await Managers.User.createCreator(creator, adventureFirebaseId);
  };

  const successfulSubmission = () => {
    closeModal();
    openModal(
      <Popups.Confirm
        title="Success!"
        text="The adventure has successfully been added to the adventures library! Thanks for you submission!"
      />,
    );
    console.log(
      "%c ####################################################################################################",
      "background-color: green",
    );
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(
      "%c ####################################################################################################",
      "background-color: green",
    );
    console.log("Handling Submission");

    if (!adventureData.length) {
      noLengthProvided();
      return;
    }

    const shareLinkData = getDataFromShareLink(adventureData.shareLink);
    if (!shareLinkData) {
      faultyShareLink();
      return;
    }

    console.log(shareLinkData);

    adventureData.name = shareLinkData.name;
    adventureData.link = shareLinkData.link;
    adventureData.creator = shareLinkData.creator;

    // creator<string>
    const { name, link, creator } = adventureData;

    if (!(await verifyLink(link, invalidLink, linkNameFail))) {
      return;
    }

    // Create Adventure
    const adventureFirebaseId =
      await Managers.Adventure.createAdventure(adventureData);

    // Check for Creator
    const creators = await Managers.User.getAllCreators();

    let foundCreator = false;
    for (let i = 0; i < creators.length; i++) {
      if (creators[i].username.toLowerCase() === creator.toLowerCase()) {
        foundCreator = true;
      }
    }

    if (foundCreator) {
      console.log("Creator found");
      await creatorExists(creator, adventureFirebaseId);
    } else {
      console.log("Creator not found");
      await creatorDoesNotExist(creator, adventureFirebaseId);
    }

    await creatorDoesNotExist(creator, adventureFirebaseId);

    console.info("Successful Submission");
    await Managers.Firebase.addItemToArrayField(
      "adventureLinks",
      "links001",
      "links",
      link.split("/adv/")[1],
    );

    const user = await Managers.User.getUserProfile(
      Managers.Firebase.currentUser.displayName,
    );

    await Managers.Firebase.incrementField(
      "users",
      user.username,
      "submissions",
    );

    console.log("displayName: ", Managers.Firebase.currentUser.displayName);
    console.log("User:", user);
    successfulSubmission();
  };

  const getDataFromShareLink = (inputString) => {
    console.log(inputString);
    const regex =
      /.*Wonderbox: “(.*)” by (.*) (https:\/\/playwonderbox.com\/adv\/#.*)/;
    const matches = inputString.match(regex);
    console.log(matches);

    if (matches) {
      return {
        name: matches[1],
        creator: matches[2],
        link: matches[3],
      };
    } else {
      return null;
    }
  };

  const formData = [
    {
      label: "Share Link",
      type: "textarea",
      name: "shareLink",
      className: "submission-textarea",
      handleChange: handleChange,
      value: adventureData.shareLink,
      required: true,
    },
    {
      label: "Length",
      type: "select",
      name: "length",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.length,
      required: true,
      defaultOption: "Select Length",
      options: adventureLengths,
    },
    {
      label: "Plays",
      type: "number",
      name: "plays",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.plays,
      required: false,
    },
    {
      label: "Likes",
      type: "number",
      name: "likes",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.likes,
      required: false,
    },

    {
      label: "Created",
      type: "date",
      name: "created",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.created,
      required: false,
    },
  ];
  return (
    <div>
      <Forms.Base
        formData={formData}
        handleSubmit={handleSubmit}
        submitText="Submit"
        layout="double"
      />
    </div>
  );
}
