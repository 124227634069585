const isLive = true;
// Store the original console methods before they are overridden
const originalConsole = {
  log: console.log,
  warn: console.warn,
  error: console.error,
  info: console.info,
  table: console.table,
  // ... any other methods you want to store
};

// Override the console methods
console.log = function (...args) {
  if (isLive) return;
  // Your custom logic here
  originalConsole.log.apply(console, args);
};

console.warn = function (...args) {
  if (isLive) return;
  // Your custom logic here
  originalConsole.warn.apply(console, args);
};

console.error = function (...args) {
  if (isLive) return;
  // Your custom logic here
  originalConsole.error.apply(console, args);
};

console.info = function (...args) {
  if (isLive) return;
  // Your custom logic here
  originalConsole.info.apply(console, args);
};

console.table = function (...args) {
  if (isLive) return;
  // Your custom logic here
  originalConsole.table.apply(console, args);
};

const consoleExport = {};
export default consoleExport;
