// BaseForm.js

import React from "react";
import { useEffect, useRef, useState } from "react";
import Buttons from "../Buttons/Buttons";

export default function BaseForm({
  formData,
  handleSubmit,
  submitText = "Submit",
  layout = "single",
  showAll = false,
}) {
  const [buttonState, setButtonState] = useState(false);
  const [showOptional, setShowOptional] = useState(false);
  const [hasOptional, setHasOptional] = useState(false);

  const submitButtonRef = useRef();

  const isFormReady = () => {
    return formData.every((field) => {
      if (field.required) {
        return field.value !== "";
      }
      return true;
    });
  };

  const onSubmit = (e) => {
    console.log("onSubmit(e) triggered.\ne:");
    console.log(e);
    handleSubmit(e);
  };

  useEffect(() => {
    if (showAll) {
      setShowOptional(true);
    }

    const hasOptionalField = formData.some((field) => field.required === false);
    setHasOptional(hasOptionalField);
  }, []);

  useEffect(() => {
    if (isFormReady()) {
      setButtonState(true);
    } else {
      setButtonState(false);
    }
  }, [formData]);

  return (
    <form onSubmit={onSubmit}>
      <div
        className={`form-row ${layout === "double" ? "form-row-double" : ""}`}
      >
        <div
          className={`form-col ${
            layout === "double" ? "form-col-double" : "form-col-single"
          }`}
        >
          {formData.map((field) => {
            if (field.required) {
              return (
                <div className="form-field" key={field.name}>
                  <label htmlFor={field.name}>{field.label}:</label>
                  {field.type === "textarea" ? (
                    <textarea
                      id={field.name}
                      className={field.className}
                      name={field.name}
                      onChange={field.handleChange}
                      value={field.value}
                      cols={30}
                      rows={4} // adjust as needed
                      required={field.required}
                    />
                  ) : field.type !== "select" ? (
                    <input
                      type={field.type}
                      id={field.name}
                      className={field.className}
                      name={field.name}
                      onChange={field.handleChange}
                      value={field.value}
                      cols={30}
                      required={field.required}
                    />
                  ) : (
                    <select
                      id={field.name}
                      className={field.className}
                      name={field.name}
                      onChange={field.handleChange}
                      value={field.value}
                      required={field.required}
                    >
                      <option value="">{field.defaultOption}</option>
                      {field.options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              );
            } else {
              // skip non-required fields
              return null;
            }
          })}
        </div>
      </div>
      <div
        className={`optional-fields ${showOptional || showAll ? "show" : ""}`}
      >
        <div
          className={`form-row ${layout === "double" ? "form-row-double" : ""}`}
        >
          <div
            className={`form-col ${
              layout === "double" ? "form-col-double" : "form-col-single"
            }`}
          >
            {formData.map((field) => {
              if (!field.required) {
                return (
                  <div className="form-field" key={field.name}>
                    <label htmlFor={field.name}>{field.label}:</label>
                    {field.type === "textarea" ? (
                      <textarea
                        id={field.name}
                        className={field.className}
                        name={field.name}
                        onChange={field.handleChange}
                        value={field.value}
                        cols={30}
                        rows={4} // adjust as needed
                        required={field.required}
                      />
                    ) : field.type !== "select" ? (
                      <input
                        type={field.type}
                        id={field.name}
                        className={field.className}
                        name={field.name}
                        onChange={field.handleChange}
                        value={field.value}
                        cols={30}
                        required={field.required}
                      />
                    ) : (
                      <select
                        id={field.name}
                        className={field.className}
                        name={field.name}
                        onChange={field.handleChange}
                        value={field.value}
                        required={field.required}
                      >
                        <option value="">{field.defaultOption}</option>
                        {field.options.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                );
              } else {
                // skip required fields
                return null;
              }
            })}
          </div>
        </div>
      </div>
      {hasOptional && !showAll && (
        <div className="wide-button-container" style={{ width: "100%" }}>
          <div
            className="wide-button-inside"
            onClick={() => setShowOptional(!showOptional)}
          >
            Toggle Optional Fields
          </div>
        </div>
      )}
      <br />
      <Buttons.Flashy
        text={submitText}
        width="100%"
        buttonState={buttonState}
        onClick={onSubmit}
      />
    </form>
  );
}
