// Icons.js

import React from "react";
import { ReactComponent as pencil } from "./SVGs/pencil.svg";

function playbutton() {
  return <div className="triangle" />;
}

const Icons = {
  playbutton,
  pencil,
};

export default Icons;
