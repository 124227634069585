// SubmitAdventure.js

import React, { useContext, useEffect, useState } from "react";
import Managers from "../Managers";
import Popups from "../Components/Popups/Popups";
import Forms from "../Components/Forms/Forms";
import { adventureLengths } from "./AdventureMarkers.js";
import verifyLink from "./SubmitAdventure/VerifyLink";
import { useModal } from "../ModalManager.js";

export default function EditAdventure({ adventure }) {
  const { openModal, closeModal } = useModal();
  const [formType, setFormType] = useState(!formType);
  const [adventureData, setAdventureData] = useState({
    name: "",
    link: "",
    plays: 0,
    likes: 0,
    creator: "",
    length: "",
    created: "",
  });
  const [baseData, setBaseData] = useState(adventureData);

  useEffect(() => {
    console.log(
      "%c ####################################################################################################",
      "background-color: green"
    );
    refreshAdventureData();
  }, []); // Place the useEffect call here

  const refreshAdventureData = async () => {
    console.log("Adventure Data:", adventure.firebaseId);
    const newData = await Managers.Adventure.getAdventure(adventure.firebaseId);
    console.table("Retrieved Adventure newData 1:", newData);
    setAdventureData(newData);
    setBaseData(newData);
    console.table("Retrieved Adventure newData 2:", newData);
    console.table("Retrieved Adventure adventureData:", adventureData);
  };

  const handleChange = (e) => {
    setAdventureData({
      ...adventureData,
      [e.target.name]: e.target.value,
    });
  };

  const invalidLink = () => {
    openModal(
      <Popups.Confirm
        title="Invalid Wonderbox link"
        text="The link provided is not from the correct domain. This is a security feature. If the link you wish to provie should be accurate, please contact Bishiba#7478 @ Discord or through the official Wonderbox Discord."
      />
    );
    return false;
  };

  const linkNameFail = () => {
    openModal(
      <Popups.Confirm
        title="Link Occupied"
        text="An adventure with this link already exists."
      />
    );
    return false;
  };

  const nameTaken = () => {
    openModal(
      <Popups.Confirm
        title="Adventure Name Taken"
        text="An adventure with this name already exists."
      />
    );
    return false;
  };

  const errorPopup = (error) => {
    openModal(<Popups.Exception title="Exception!" error={error} />);
    return false;
  };

  const creatorExists = async (creator, adventure) => {
    console.log("Creator does not exist", creator);
    console.log("Adventure", adventure.name);
    await Managers.User.removeAdventureFromCreator(
      adventure.creator,
      adventure.firebaseId
    );
    await Managers.User.addAdventureToCreator(creator, adventure.firebaseId);
  };

  const creatorDoesNotExist = async (creator, adventure) => {
    console.log("Creator does not exist", creator);
    console.log("Adventure", adventure.name);
    await Managers.User.createCreator(creator, adventure.firebaseId);
  };

  const successfulUpdate = () => {
    try {
      console.log("Closing the edit modal");
      closeModal();
      console.log("Opening the successfulUpdate modal");
      openModal(
        <Popups.Confirm
          title="Success!"
          text="The adventure has been successfully updated!"
        />
      );
      console.log("Log after successfulUpdate modal");
    } catch (error) {
      console.error("successfulUpdate Modal failed to display");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      console.warn("Handling Adventure Update!!!!!!!!!!!!!!!!!!!!!!!");

      const updateFields = {};
      console.log(`Checking which fields to update`);
      for (let key in adventureData) {
        console.log(`Should ${key} be updated?`);
        if (baseData[key] !== adventureData[key]) {
          console.info(
            `${baseData[key]} !== ${adventureData[key]} is ${
              baseData[key] !== adventureData[key]
            }`
          );
          updateFields[key] = adventureData[key];
          console.log(`Yes`);
        } else {
          console.log(`No`);
        }
      }
      console.log(`Check completed. See object updateFields`, updateFields);

      // creator<string>
      const { name, link, creator } = adventureData;

      if (updateFields.link)
        if (!verifyLink(link, invalidLink, linkNameFail)) {
          return;
        }

      if (updateFields.creator) {
        // Check for Creator
        const creators = await Managers.User.getAllCreators();

        let foundCreator = false;
        for (let i = 0; i < creators.length; i++) {
          if (creators[i].username.toLowerCase() === creator.toLowerCase()) {
            foundCreator = true;
          }
        }

        if (foundCreator) {
          await creatorExists(creator, adventure.firebaseId);
        } else {
          await creatorDoesNotExist(creator, adventure.firebaseId);
        }
      }

      console.warn("Updating Adventure #############################");
      await Managers.Adventure.updateAdventure(adventure, updateFields);

      console.info("Updating Local Adventures");
      //await Managers.Adventure.updateLocalAdventures(true);
      //console.info("Successful Update Modal");
      successfulUpdate();
      console.info("Update Adventure Complete");
    } catch (error) {
      errorPopup(error);
    }
  };

  const formData = [
    {
      label: "Name",
      type: "textarea",
      name: "name",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.name,
      required: false,
    },
    {
      label: "Link",
      type: "textarea",
      name: "link",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.link,
      required: false,
    },
    {
      label: "Creator",
      type: "textarea",
      name: "creator",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.creator,
      required: false,
    },
    {
      label: "Length",
      type: "select",
      name: "length",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.length,
      required: false,
      defaultOption: "Select Length",
      options: adventureLengths,
    },
    {
      label: "Plays",
      type: "number",
      name: "plays",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.plays,
      required: false,
    },
    {
      label: "Likes",
      type: "number",
      name: "likes",
      className: "submission-inputs",
      handleChange: handleChange,
      value: adventureData.likes,
      required: false,
    },

    {
      label: "Created",
      type: "date",
      name: "created",
      className: "submission-inputs",
      handleChange: handleChange,
      value: new Date(
        adventureData.created.replace("!", "").split("-").reverse().join("-")
      ),
      required: false,
    },
  ];
  return (
    <div>
      <Forms.Base
        formData={formData}
        handleSubmit={handleUpdate}
        submitText="Submit"
        layout="double"
        showAll={true}
      />
    </div>
  );
}
