// Footer.js

export default function Footer() {
  // Navigate to App Store
  const navigateToAppStore = () => {
    window.location.href = "https://apps.apple.com/app/id1520720139";
  };

  return (
    <div id="footer-element">
      <div className="button-holder">
        <div className="button-holder">
          <a href="https://apps.apple.com/app/id1520720139" target="_blank">
            <img
              className="apple-button"
              src="https://playwonderbox.com/deeplink/assets/wonderbox_app_icon@1x.png"
              alt="Wonderbox Icon"
            />
          </a>

          <a href="https://apps.apple.com/app/id1520720139" target="_blank">
            <img
              className="apple-button"
              src="https://playwonderbox.com/deeplink/assets/app_store_badge_black@1x.png"
              alt="Apple Badge"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
