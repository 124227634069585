import React, { useState } from "react";
import InputRange from "react-input-range";

const MinMax = ({
  title = "Range",
  minValue = 0,
  maxValue = 100,
  step = 1,
  onChange,
}) => {
  const [value, setValue] = useState({ min: minValue, max: maxValue });

  const handleChange = (value) => {
    console.log("value", value);
    value.min = parseFloat(value.min.toFixed(1));
    value.max = parseFloat(value.max.toFixed(1));
    setValue(value);
    onChange(value);
  };

  return (
    <div style={{ textAlign: "center" }}>
      {title}
      <InputRange
        minValue={minValue}
        maxValue={maxValue}
        value={value}
        onChange={handleChange}
        step={step}
      />
      <p style={{ visibility: "hidden" }}>
        Min Value: {value.min.toFixed(1)}, Max Value: {value.max.toFixed(1)}
      </p>
    </div>
  );
};

export default MinMax;
