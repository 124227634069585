// Sideslide.js

// import Sideslide from "../Buttons/Sideslide";

// Sideslide is a menu that holds button. The Top button and its text is always visible.
// You will get as many buttons in the Sideslide menu as you parsed in through the buttons arrys.
// A button is defined as {buttonText, onClick}.
import { getLongestStringLength } from "../../HelperFunctions/StringFunctions";

export default function Sideslide({ buttons = [], buttonWidth = "90px" }) {
  buttons.reverse();
  buttons = [...buttons].reverse();
  buttons = buttons.filter(Boolean);
  const topButton = buttons[0] || { buttonText: "Default", onClick: () => {} };
  const otherButtons = buttons.slice(1);
  const longestString = getLongestStringLength(buttons, "buttonText");

  buttonWidth = longestString * 15 + "px";

  // Sets the gap between the buttons when expanded.
  const ulStyle = {
    gridTemplateColumns: `auto repeat(var(--button-count), calc((var(--active, 0) * ${buttonWidth}) + 10px))`,
  };

  return (
    <main
      className="sideslide-body"
      style={{ "--button-count": otherButtons.length }}
    >
      <nav className="sideslide-nav">
        <ul className="sideslide-ul" style={ulStyle}>
          <li className="sideslide-li" style={{ "--index": 0 }}>
            <div
              className="sideslide-a"
              style={{ width: buttonWidth }}
              href="#"
              onClick={topButton.onClick}
            >
              <span className="sideslide-span">{topButton.buttonText}</span>
            </div>
          </li>
          {otherButtons.map((button, index) => (
            <li
              className="sideslide-li"
              style={{ "--index": index + 1 }}
              key={index + 1}
            >
              <div
                className="sideslide-a"
                style={{ width: buttonWidth }}
                href="#"
                onClick={button.onClick}
              >
                <span className="sideslide-span">{button.buttonText}</span>
              </div>
            </li>
          ))}
        </ul>
      </nav>
    </main>
  );
}
