// Initialize.js
import React from "react";
import Managers from "./Managers";

async function InitializeWonderbox(onInitialized) {
  if (Managers.initilized) {
    console.warn("Attempted to initialize again.");
  }

  // Auth initialization
  Managers.Firebase.functionsOnAuthChanged.push(
    Managers.User.refreshCurrentUserProfile
  );

  // User initialization
  console.log("Initializing Users");
  if (Managers.Firebase.currentUser) {
    console.log("Initializing Current User");
    Managers.User.initializeUserProfile();
  }

  // Listen to adventures collection
  console.log("Attaching Listener to Adventures Collection");
  await Managers.Firebase.listenToCollectionChanges(
    "adventures",
    Managers.Adventure.onAdventureChanged
  );

  console.log("Initialization Done");
  Managers.initilized = true;
  Managers.onInitialized(true);
}

Managers.initialize = InitializeWonderbox;
