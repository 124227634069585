import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAnnmTxy5_L_HD3SNYbmDSM58vVhnL7tQY",
  authDomain: "wonderboxdatabase.firebaseapp.com",
  projectId: "wonderboxdatabase",
  storageBucket: "wonderboxdatabase.appspot.com",
  messagingSenderId: "56356250699",
  appId: "1:56356250699:web:4e82108f31784608d019ce",
  measurementId: "G-ZGCP1KH8BD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const firebaseDatabase = getFirestore(app);

export default firebaseDatabase;
