//App.js
import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from "react";
import AdventureTable from "./Adventures/AdventureTable";
import { ModalProvider } from "./ModalManager";
import "./styles/main.css";
import Managers from "./Managers";
import Pages from "./Pages/Pages";
import InitializeWonderbox from "./Initialize";
import "react-input-range/lib/css/index.css";

export default function App() {
  const [initialized, setInitialized] = useState(false);

  const onInitialized = (initialized) => {
    setInitialized(true);
  };

  useEffect(() => {
    if (initialized === true) return;

    Managers.onInitialized = onInitialized;
    Managers.initialize();
  }, []);

  return <Pages.Master initialized={initialized} />;
}
