// PasswordRequirements.js

export default function verifyPasswordRequirements(password) {
  // Create a list of conditions for a valid password
  const conditions = [
    {
      test: (password) => password.length >= 8,
      message: "Password should be at least 8 characters long.",
    },
    {
      test: (password) => /[a-z]/.test(password),
      message: "Password should contain at least one lowercase letter.",
    },
    {
      test: (password) => /[A-Z]/.test(password),
      message: "Password should contain at least one uppercase letter.",
    },
    {
      test: (password) => /\d/.test(password),
      message: "Password should contain at least one number.",
    },
    {
      test: (password) => /[\W_]/.test(password),
      message: "Password should contain at least one special character.",
    },
  ];

  // Check each condition
  for (let condition of conditions) {
    if (!condition.test(password)) {
      // If the password does not meet a condition, return the message for that condition
      return { isValid: false, message: condition.message };
    }
  }

  // If the password meets all conditions, return true
  return { isValid: true, message: "" };
}
