import React, { useEffect, useState } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import Buttons from "../Components/Buttons/Buttons";
import Forms from "../Components/Forms/Forms";
import Popups from "../Components/Popups/Popups";
import Managers from "../Managers";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const formData = [
    {
      label: "Email",
      type: "email",
      name: "email",
      className: "submission-inputs",
      handleChange: (e) => setEmail(e.target.value),
      value: email,
      required: true,
    },
    {
      label: "Password",
      type: "password",
      name: "password",
      className: "submission-inputs",
      handleChange: (e) => setPassword(e.target.value),
      value: password,
      required: true,
    },
  ];

  const login = async (event) => {
    event.preventDefault();

    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      Managers.closeModal();
      // You may want to do something here, like navigate to the logged in page
    } catch (error) {
      Managers.openModal(
        <Popups.Exception title="Sign in error" error={error} />
      );
      return;
    }
  };

  const switchToRegister = () => {
    Managers.closeModal();
    Managers.openModal(<Managers.Password.Register />);
  };

  const recoverPassword = () => {
    Managers.closeModal();
    Managers.openModal(<Managers.Password.Recover />);
  };

  return (
    <Popups.Form
      title="Login"
      form={
        <div>
          <Forms.Base
            formData={formData}
            handleSubmit={login}
            submitText="Login"
          />
          <div className="button-column">
            <br />
            <Buttons.Flashy
              text="Need an Account?"
              onClick={() => switchToRegister()}
            />
            <br />
          </div>
          <div className="forgot-password-link">
            <a href="#" onClick={recoverPassword}>
              Forgot your password?
            </a>
          </div>
        </div>
      }
    />
  );
}
