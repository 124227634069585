export function getLongestStringLength(arr, property) {
  let longestLength = 0;

  for (let i = 0; i < arr.length; i++) {
    let currentString = "";

    if (typeof arr[i] === "string") {
      currentString = arr[i];
    } else if (typeof arr[i] === "object" && arr[i].hasOwnProperty(property)) {
      currentString = arr[i][property];
    }

    if (currentString.length > longestLength) {
      longestLength = currentString.length;
    }
  }

  return longestLength;
}

export function splitAndCapitalizeCamelCase(str) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // split words at camel case
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // capitalize first letter of each word
    .join(" ");
}
