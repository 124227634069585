import React, { useEffect, useState } from "react";
import Managers from "../../Managers";
import Buttons from "../../Components/Buttons/Buttons";
import UserDescriptionUpdate from "../../UserManagement/UserDescription";
import AdventureTable from "../../Adventures/AdventureTable";
import PlaylistTable from "../../Playlists/PlaylistTable";
import { useModal } from "../../ModalManager";
import Popups from "../../Components/Popups/Popups";

function UserProfilePage({ profileUsername }) {
  const [currentProfile, setCurrentProfile] = useState(null);
  const [userPlaylists, setUserPlaylists] = useState([]);
  const { openModal } = useModal();

  useEffect(() => {
    const fetchUserProfile = async () => {
      await Managers.User.getUserProfile(Managers.Firebase.currentUser).then(
        (userProfile) => {
          setCurrentProfile(userProfile);
          console.log(">>>>>>>>>>>>>>>userProfile:", userProfile);
        }
      );
    };
    console.log("<<<<<<<<<<currentProfile:", currentProfile);
    fetchUserProfile();
    fetchUserPlaylists();
  }, [Managers.Firebase.currentUser]);

  const fetchUserPlaylists = async () => {
    console.log(
      "Managers.Firebase.currentUser.displayName",
      Managers.Firebase.currentUser.displayName
    );
    const playlists = await Managers.Playlist.getUserPlaylists(
      Managers.Firebase.currentUser.displayName
    );
    setUserPlaylists(playlists || []);
    console.log("-------------------playlists:", playlists);
    console.log("-------------------userPlaylists:", userPlaylists);
  };

  const updatePlaylists = async () => {
    await fetchUserPlaylists();
  };

  const isOwner = () => {
    return Managers.Firebase.currentUser.displayName === profileUsername
      ? true
      : false;
  };

  const openPlaylist = async (playlist) => {
    console.error("PASSING PLAYLIST", playlist);
    openModal(
      <Popups.Table
        title={playlist.name}
        table={
          <AdventureTable
            basePlaylist={playlist}
            onUpdateFunction={() => updatePlaylists()}
          />
        }
      />
    );
  };

  return currentProfile != null ? (
    <div className="profile-page">
      {currentProfile?.username && (
        <h2>{currentProfile?.username}'s Profile</h2>
      )}
      <div className="description-holder">
        <div className="user-description">
          {currentProfile?.description}
          {isOwner() && (
            <Buttons.Normal
              text="Edit Description"
              onClick={() =>
                openModal(
                  <UserDescriptionUpdate currentProfile={currentProfile} />
                )
              }
            />
          )}
        </div>
      </div>
      {console.log("userPlaylists", userPlaylists)}
      {userPlaylists && (
        <PlaylistTable
          playlistArray={userPlaylists}
          functionOnSelect={(playlist) => openPlaylist(playlist)}
        />
      )}
      <br />
      Settings
      <br />
      {isOwner() && (
        <Buttons.Normal
          text="Change Password"
          onClick={() => openModal(<Managers.Password.ChangePasswordForm />)}
        />
      )}
    </div>
  ) : (
    <div className="profile-page" />
  );
}

export default UserProfilePage;
