import React, { useState } from "react";

const Text = ({ onChange }) => {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    const text = event.target.value;
    setValue(text);
    if (onChange) onChange(event);
  };

  return (
    <div>
      <input type="text" value={value} onChange={handleChange} />
    </div>
  );
};

export default Text;
