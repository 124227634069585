import Select from "./Select";
import Slider from "./Slider";
import Area from "./Area";
import Text from "./Text";
import MinMax from "./MinMax";

const Inputs = {
  Select,
  Slider,
  Area,
  Text,
  MinMax,
};

export default Inputs;
