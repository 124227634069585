// PasswordManager.js
import ChangePasswordForm from "../Authentication/ChangePassword";
import Login from "../Authentication/Login";
import logoutUser from "../Authentication/logoutUser";
import Recover from "../Authentication/Recover";
import Register from "../Authentication/Register";
import useLogin from "../Authentication/useLogin";
import verifyPasswordRequirements from "../Authentication/verifyPasswordRequirements";

const PasswordManager = {
  ChangePasswordForm,
  Login,
  logoutUser,
  Recover,
  Register,
  useLogin,
  verifyPasswordRequirements,
};

export default PasswordManager;
