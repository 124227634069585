// GeneralTable.js
import React from "react";
import { useTable, useSortBy } from "react-table";
import { splitAndCapitalizeCamelCase } from "../../HelperFunctions/StringFunctions";

export default function GeneralTable({ objectArray, propertyNames, elements }) {
  const columns = React.useMemo(
    () => [
      ...propertyNames.map((property) => {
        let propertyName, accessor;
        if (typeof property === "string") {
          propertyName = property;
          accessor = property;
        } else if (typeof property === "object") {
          propertyName = property.propertyName;
          accessor = property.accessor;
        }
        return {
          Header: splitAndCapitalizeCamelCase(propertyName),
          accessor: accessor,
        };
      }),
      {
        Header: "Actions",
        accessor: "elements",
        Cell: ({ row }) => {
          return elements.map((Element, index) => {
            return <Element key={index} row={row.original} />;
          });
        },
        preventSorting: true,
      },
    ],
    [propertyNames, elements]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: objectArray }, useSortBy);

  return (
    <div className="general-table-window">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...(column.preventSorting
                    ? column.getHeaderProps()
                    : column.getHeaderProps(column.getSortByToggleProps()))}
                >
                  {column.render("Header")}
                  <span>
                    {column.preventSorting
                      ? null
                      : column.isSorted
                      ? column.isSortedDesc
                        ? " ⧨"
                        : " ◮"
                      : " ◪"}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
