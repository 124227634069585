// ModalManager.js

import React, { createContext, useContext, useState, useEffect } from "react";

const ModalContext = createContext();

export function ModalProvider({ children, setIsModalOpen }) {
  const [modalElements, setModalElements] = useState([]);

  const openModal = (element) => {
    console.log("openModal", element);

    setModalElements((prevElements) => {
      window.modals = modalElements;
      // If the incoming element is unique, check for duplicates
      if (element.props.unique) {
        const duplicateModal = prevElements.find(
          (modal) => modal.type.name === element.type.name
        );

        // If a duplicate is found, return the previous state without adding the new element
        if (duplicateModal) {
          console.log(`Modal of type ${element.type.name} is already open.`);
          return prevElements;
        }
      }

      // If no duplicates are found or the element is not unique, add it to the state
      return [...prevElements, element];
    });
  };

  const closeModal = () => {
    setModalElements((prevElements) =>
      prevElements.slice(0, prevElements.length - 1)
    );
  };

  // Close duplicate uniques but keep one open
  useEffect(() => {
    const uniqueModals = modalElements.reduce((acc, modal) => {
      // If the modal is unique and not already in the accumulator, add it
      if (
        modal.props.unique &&
        !acc.some((m) => m.type.name === modal.type.name)
      ) {
        acc.push(modal);
      }
      // If the modal is not unique, add it unconditionally
      else if (!modal.props.unique) {
        acc.push(modal);
      }
      return acc;
    }, []);

    // Update the state if there are any duplicates
    if (uniqueModals.length !== modalElements.length) {
      setModalElements(uniqueModals);
    }
    console.log("modalElements", modalElements);
    if (modalElements.length === 0) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
  }, [modalElements]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      {modalElements.map((modalElement, index) =>
        React.cloneElement(modalElement, { key: index })
      )}
    </ModalContext.Provider>
  );
}

export function useModal() {
  return useContext(ModalContext);
}
