// CookieManager.js
import Cookies from "universal-cookie";
import Managers from "../Managers";
import React from "react";
import Popups from "../Components/Popups/Popups";
import Pages from "../Pages/Pages";

const cookies = new Cookies();

/**
 * A module to handle cookies
 *
 * @module CookieManager
 */
const CookieManager = {
  /**
   * Set a cookie
   *
   * @param {string} key - The key (name) of the cookie
   * @param {any} value - The value of the cookie
   * @param {Object} [options] - Optional settings for the cookie (path, domain, secure, etc.)
   */
  set: (key, value, options) => {
    cookies.set(key, value, options);
  },

  /**
   * Get a cookie
   *
   * @param {string} key - The key (name) of the cookie
   * @returns {any} The value of the cookie, or null if not found
   */
  get: (key) => {
    return cookies.get(key) || null;
  },

  /**
   * Delete a cookie
   *
   * @param {string} key - The key (name) of the cookie
   * @param {Object} [options] - Optional settings for the cookie (path, domain, secure, etc.)
   */
  delete: (key, options) => {
    cookies.remove(key, options);
  },

  /**
   * Accept cookies
   *
   * This will set a cookie indicating that the user has accepted cookies.
   */
  accept: () => {
    cookies.set("cookies_accepted", true, { path: "/" });
    if (!Managers.initilized) {
      Managers.initialize();
    }
  },

  /**
   * Deny cookies
   *
   * This will set a cookie indicating that the user has denied cookies.
   */
  deny: () => {
    cookies.set("cookies_accepted", false, { path: "/" });
    CookieManager.hasDenied = true;
  },
  hasDenied: false,

  /**
   * Check cookie preference
   *
   * This will check if the user has accepted or denied cookies.
   * @returns {string} - 'accepted' if the user has accepted cookies,
   * 'denied' if the user has denied cookies, or 'not set' if the user has not made a choice.
   */
  check: () => {
    const cookiePreference =
      cookies.get("cookies_accepted") == "true" ? true : false;
    if (
      !cookiePreference &&
      !CookieManager.notificationShown &&
      CookieManager.hasDenied &&
      Pages.current != Pages.names[3]
    ) {
      CookieManager.notifyDenied();
    }
    return cookiePreference;
  },

  /**
   * Notify client that site does not work due to not having accepted policy
   *
   * This will remove all cookies set by your application.
   */
  notifyDenied: () => {
    CookieManager.notificationShown = true;
    const title = "Policy not accepted.";
    const error =
      "Error encountered due to not having accepted the website policy terms.";

    console.error("Policy not accepted.");
    Managers.openModal(<Popups.Exception title={title} error={error} unique />);
  },

  notificationShown: false,

  /**
   * Clear cookies
   *
   * This will remove all cookies set by your application.
   */
  clear: () => {
    const allCookies = cookies.getAll();
    Object.keys(allCookies).forEach((key) =>
      cookies.remove(key, { path: "/" })
    );
  },
};

export default CookieManager;
