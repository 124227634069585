// AdventureItem.js

import Managers from "../../Managers";
import AdventureMarkers from "../../Adventures/AdventureMarkers";
import Buttons from "../Buttons/Buttons";
import Popups from "../Popups/Popups";
import PlaylistTable from "../../Playlists/PlaylistTable";
import Icons from "../../Icons";
import EditAdventure from "../../Adventures/EditAdventure";
import Menus from "../Menus/Menus";
import React, { useEffect, useState } from "react";

export default function AdventureItem({
  headers,
  adventure,
  onClick,
  playlist,
  onUpdateFunction,
  onHeaderClick,
  sortIndicator,
}) {
  if (!headers && !adventure) return null;
  const [userRating, setUserRating] = adventure
    ? useState(adventure.userRating || "0")
    : useState("");
  const [userDifficulty, setUserDifficulty] = adventure
    ? useState(adventure.userDifficulty || "0")
    : useState("");
  const handleClick = () => {
    console.log(`Clicked ${adventure.name}`);
  };

  const handleHeaderClick = (headerName) => {
    console.log(headerName);
    if (onHeaderClick) {
      onHeaderClick(headerName);
    }
  };

  let name, creator, category, length, rating, difficulty;

  if (adventure) {
    name = adventure.name;
    creator = adventure.creator;
    category = adventure.category;
    length = adventure.length;
    rating = adventure.rating + "(" + adventure.numOfRatings + ")";
    difficulty =
      adventure.difficulty + "(" + adventure.numOfDifficultyVotes + ")";
  } else if (headers) {
    const headersWithSortIndicator = {
      name:
        headers.name +
        (sortIndicator?.name
          ? ` ${sortIndicator.name === "asc" ? "◮" : "⧨"}`
          : ""),
      creator:
        headers.creator +
        (sortIndicator?.creator
          ? ` ${sortIndicator.creator === "asc" ? "◮" : "⧨"}`
          : ""),
      category:
        headers.category +
        (sortIndicator?.category
          ? ` ${sortIndicator.category === "asc" ? "◮" : "⧨"}`
          : ""),
      length:
        headers.length +
        (sortIndicator?.length
          ? ` ${sortIndicator.length === "asc" ? "◮" : "⧨"}`
          : ""),
      rating:
        headers.rating +
        (sortIndicator?.rating
          ? ` ${sortIndicator.rating === "asc" ? "◮" : "⧨"}`
          : ""),
      difficulty:
        headers.difficulty +
        (sortIndicator?.difficulty
          ? ` ${sortIndicator.difficulty === "asc" ? "◮" : "⧨"}`
          : ""),
    };

    name = headersWithSortIndicator.name;
    creator = headersWithSortIndicator.creator;
    category = headersWithSortIndicator.category;
    length = headersWithSortIndicator.length;
    rating = headersWithSortIndicator.rating;
    difficulty = headersWithSortIndicator.difficulty;
  }

  const widths = ["5%", "10%", "15%", "20%", "25%", "30%"];

  // Playlist

  const confirmToPlaylist = (playlist, adventure) => {
    console.log("confirmToPlaylist", playlist, adventure);
    Managers.Playlist.addAdventureToPlaylist(playlist, adventure);
    Managers.closeModal();
  };

  // Buttons
  const rateUser = (r) => {
    const rating = r;
    Managers.Adventure.rateAdventure(adventure, rating);
    setUserRating(rating);
  };
  const ratingButtons = [
    {
      name: "1",
      onClick: () => rateUser(1),
    },
    {
      name: "2",
      onClick: () => rateUser(2),
    },
    {
      name: "3",
      onClick: () => rateUser(3),
    },
    {
      name: "4",
      onClick: () => rateUser(4),
    },
    {
      name: "5",
      onClick: () => rateUser(5),
    },
  ];

  const diffUser = async (r) => {
    const difficulty = r;
    Managers.Adventure.rateDifficulty(adventure, difficulty);
    setUserDifficulty(difficulty);
  };

  const difficultyButtons = [
    {
      name: "1",
      onClick: () => diffUser(1),
    },
    {
      name: "2",
      onClick: () => diffUser(2),
    },
    {
      name: "3",
      onClick: () => diffUser(3),
    },
    {
      name: "4",
      onClick: () => diffUser(4),
    },
    {
      name: "5",
      onClick: () => diffUser(5),
    },
  ];

  const addToPlaylist = async (adventure) => {
    if (!Managers.Firebase.currentUser) {
      Managers.openModal(<Managers.Password.Login />);
      return;
    }

    const playlists = await Managers.Playlist.getUserPlaylists(
      Managers.Firebase.currentUser.displayName,
    );

    Managers.openModal(
      <Popups.Table
        title={`Add ${adventure.name} to Playlist`}
        table={
          <PlaylistTable
            playlistArray={playlists}
            functionOnSelect={(selectedPlaylist) =>
              confirmToPlaylist(selectedPlaylist, adventure)
            }
          />
        }
      />,
    );

    if (onUpdateFunction) onUpdateFunction();
    console.log(adventure);
  };

  const removeFromPlaylist = async (adventure) => {
    console.log("REMOVED ADVENTURE FROM PLAYLIST:", playlist);
    const updatedPlaylist = await Managers.Playlist.removeAdventureFromPlaylist(
      playlist,
      adventure,
    );

    if (onUpdateFunction) onUpdateFunction();
    const adventures =
      await Managers.Playlist.getAdventuresInPlaylist(updatedPlaylist);
  };

  const playButton = (adventure) => {
    window.open(adventure.link, "_blank");
  };

  const editButton = (adventure) => {
    Managers.openModal(
      <Popups.Form
        title={`Edit ${adventure.name}`}
        form={<EditAdventure adventure={adventure} />}
      />,
    );
  };

  let actionButtons = [];
  if (headers) {
    actionButtons = ["Actions"];
  }
  if (adventure) {
    actionButtons = [
      <div className="tr-button-container">
        {playlist == null ? (
          <Buttons.Round text="+" onClick={() => addToPlaylist(adventure)} />
        ) : (
          <Buttons.Round
            text="-"
            onClick={() => removeFromPlaylist(adventure)}
            color="red"
          />
        )}
        <Buttons.Round
          onClick={() => playButton(adventure)}
          text={<Icons.playbutton />}
          color="green"
        />
        <Buttons.Round
          onClick={() => editButton(adventure)}
          text={<Icons.pencil />}
          color="orange"
        />
      </div>,
      // ... Add more buttons here if necessary
    ];
  }

  // onClick={adventure ? () => editButton(adventure) : null}
  return (
    <div className="item-container">
      {(adventure || headers) && (
        <div className={`item-button`}>
          <div
            className={`item-inside item-top ${headers ? "item-header" : ""}`}
            href="#"
          >
            <div
              className="item-text first"
              style={{ width: "50%" }}
              onClick={headers ? () => handleHeaderClick("Name") : null}
            >
              {name}
            </div>
            <div
              className="item-text last"
              style={{ width: "50%" }}
              onClick={headers ? () => handleHeaderClick("Creator") : null}
            >
              {creator}
            </div>
          </div>
          <div
            className={`item-inside item-bottom ${
              headers ? "item-header" : ""
            }`}
            href="#"
          >
            <div
              className="item-text"
              style={{ width: widths[4] }}
              onClick={headers ? () => handleHeaderClick("Category") : null}
            >
              {category}
            </div>
            <div
              className="item-text"
              style={{ width: widths[4] }}
              onClick={headers ? () => handleHeaderClick("Length") : null}
            >
              {length}
            </div>
            <div
              className="item-text"
              style={{
                display: "flex",
                justifyContent: "center",
                width: widths[4],
              }}
              onClick={headers ? () => handleHeaderClick("Rating") : null}
            >
              {adventure && "⭐"} {rating} {adventure && " "}
              {adventure && Managers.Firebase.currentUser && (
                <Menus.Slideup title={userRating} buttons={ratingButtons} />
              )}
            </div>
            <div
              className="item-text last"
              style={{
                display: "flex",
                justifyContent: "center",
                width: widths[4],
              }}
              onClick={headers ? () => handleHeaderClick("Difficulty") : null}
            >
              {adventure && "💀"} {difficulty}{" "}
              {adventure && Managers.Firebase.currentUser && " "}
              {adventure && Managers.Firebase.currentUser && (
                <Menus.Slideup
                  title={userDifficulty}
                  buttons={difficultyButtons}
                />
              )}
            </div>
          </div>
          <div
            className={`item-inside item-right ${headers ? "item-header" : ""}`}
            href="#"
          >
            <div className="item-text last" style={{ textAlign: "center" }}>
              {actionButtons[0]}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
