// CacheManager.js

import Managers from "../Managers";

let db;

const DB_NAME = "MyDatabase";
const OBJECT_STORE_NAME = "MyObjectStore";
const DB_VERSION = 1; // Change this if you need to upgrade the database

// Open the database connection
const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open(DB_NAME, DB_VERSION);

    openRequest.onupgradeneeded = (event) => {
      db = event.target.result;
      if (!db.objectStoreNames.contains(OBJECT_STORE_NAME)) {
        db.createObjectStore(OBJECT_STORE_NAME, { keyPath: "id" });
      }
    };

    openRequest.onsuccess = (event) => {
      db = event.target.result;
      resolve(db);
    };

    openRequest.onerror = (event) => {
      reject(event.target.error);
    };
  });
};

// Save to storage
const saveToStorage = async (id, data) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite");
    const store = transaction.objectStore(OBJECT_STORE_NAME);
    const putRequest = store.put({ id, data }); // Save the data in an object with the given id

    putRequest.onsuccess = () => {
      resolve(putRequest.result);
    };

    putRequest.onerror = () => {
      reject(putRequest.error);
    };
  }).then((data) => {
    setStorageSize();
    return data;
  });
};

// Load from storage
const loadFromStorage = async (id) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readonly");
    const store = transaction.objectStore(OBJECT_STORE_NAME);
    const getRequest = store.get(id);

    getRequest.onsuccess = () => {
      resolve(getRequest.result.data); // Extract the data from the object
    };

    getRequest.onerror = () => {
      reject(getRequest.error);
    };
  }).then(setStorageSize());
};

// Delete from storage
const deleteFromStorage = async (id) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite");
    const store = transaction.objectStore(OBJECT_STORE_NAME);
    const deleteRequest = store.delete(id);

    deleteRequest.onsuccess = () => {
      resolve(deleteRequest.result);
    };

    deleteRequest.onerror = () => {
      reject(deleteRequest.error);
    };
  });
};

// Clear storage
const clearStorage = async () => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readwrite");
    const store = transaction.objectStore(OBJECT_STORE_NAME);
    const clearRequest = store.clear();

    clearRequest.onsuccess = () => {
      resolve(clearRequest.result);
    };

    clearRequest.onerror = () => {
      reject(clearRequest.error);
    };
  });
};

// Get storage size
const setStorageSize = async () => {
  const db = await openDatabase();
  new Promise((resolve, reject) => {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readonly");
    const store = transaction.objectStore(OBJECT_STORE_NAME);
    const cursorRequest = store.openCursor();

    let size = 0;

    cursorRequest.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        const storedObject = cursor.value;
        const jsonString = JSON.stringify(storedObject);
        size += new Blob([jsonString]).size;
        cursor.continue();
      } else {
        // no more results, return size
        resolve(size);
      }
    };

    cursorRequest.onerror = () => {
      reject(cursorRequest.error);
    };
  }).then((size) => (CacheManager.size = `${size / 1000000} MB`));
};

// Check if a document exists in storage
const existsInStorage = async (id) => {
  const db = await openDatabase();
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(OBJECT_STORE_NAME, "readonly");
    const store = transaction.objectStore(OBJECT_STORE_NAME);
    const getRequest = store.get(id);

    getRequest.onsuccess = () => {
      if (getRequest.result) {
        resolve(true); // The document exists
      } else {
        resolve(false); // The document does not exist
      }
    };

    getRequest.onerror = () => {
      reject(getRequest.error);
    };
  });
};

// Add the new function to the StorageManager
const CacheManager = {
  load: loadFromStorage,
  save: saveToStorage,
  delete: deleteFromStorage,
  clear: clearStorage,
  exists: existsInStorage,
  size: "0 MB",
};

// The exported storage manager
export default CacheManager;
