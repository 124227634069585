// Import the relevant Firebase authentication module

import React, { useState } from "react";
import { getAuth, updatePassword } from "firebase/auth";
import { useModal } from "../ModalManager.js";
import Forms from "../Components/Forms/Forms";
import Popups from "../Components/Popups/Popups";
import Managers from "../Managers";

export default function ChangePasswordForm() {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const { closeModal } = useModal();

  const formData = [
    {
      label: "Current Password",
      type: "password",
      name: "password",
      className: "submission-inputs",
      handleChange: (e) => setPassword(e.target.value),
      value: password,
      required: true,
    },
    {
      label: "New Password",
      type: "password",
      name: "newPassword",
      className: "submission-inputs",
      handleChange: (e) => setNewPassword(e.target.value),
      value: newPassword,
      required: true,
    },
  ];

  const changePassword = async (event) => {
    event.preventDefault();

    // Get current user
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      try {
        // Update password
        await updatePassword(user, newPassword);
        closeModal();
        // You may want to do something here, like show a success message
      } catch (error) {
        Managers.openModal(
          <Popups.Exception
            title="Password change error"
            error={error.message}
          />
        );
        return;
      }
    } else {
      // User not signed in
      Managers.openModal(
        <Popups.Exception
          title="Password change error"
          error={"No user currently signed in."}
        />
      );
    }
  };

  return (
    <Popups.Form
      title="Change Password"
      form={
        <div>
          <Forms.Base
            formData={formData}
            handleSubmit={changePassword}
            submitText="Change Password"
          />
        </div>
      }
    />
  );
}
