// HomePage.js

import React, { useState } from "react";

function HomePage() {
  return (
    <div className="text-style-1">
      <h2>Welcome to Wonderbox Adventures!</h2>
      <br />
      <p>
        Here you can become a part of the Wonderbox community in a whole new
        way! We are working on a wikipedia logic (except we do require login)
        with the goal of having us, the players and creators, participate in
        submitting and updating adventures and their stats!
      </p>
      <br />
      <p>
        After an adventure is submitted, it joins a massive library of
        adventures that you can find in Wonderbox. But here's the kicker, you
        can actually search for adventures, filter them, rate them, comment on
        them, and give actual feedback! You can create unique playlists that
        you'll be able to share with friends.
      </p>
      <br />
      <p>
        Do you feel for an epic adventure or perhaps something really short?
        Well, you can check out the public playlists section. You can also rate
        and comment on playlists, just like you would the adventures themselves!
      </p>
      <br />
      <br />
      <br />

      <font size="+3">Current Features</font>
      <br />
      <br />
      <font size="+2">Playlists</font>
      <ol>
        <li>Create and name playlists</li>
        <li>Add adventures to playlists</li>
      </ol>
      <br />
      <font size="+2">Adventures</font>
      <ol>
        <li>Submit adventure</li>
        <li>Easy submission process</li>
        <li>Edit adventure details</li>
        <li>Duplicate prevention</li>
        <li>Creator linking (Problematic at the moment)</li>
        <li>Click and play link to open it in Wonderbox</li>
      </ol>
      <br />
      <font size="+2">Users</font>
      <ol>
        <li>Register and login</li>
        <li>Edit description</li>
      </ol>
      <br />
      <br />
      <br />

      <font size="+3">Upcoming Features</font>
      <br />
      <br />
      <font size="+2">Playlists</font>
      <ol>
        <li>Removing adventure from playlist</li>
        <li>Renaming playlist</li>
        <li>Editing playlist description</li>
        <li>Removing playlist</li>
        <li>View playlist details</li>
        <li>Private/public playlists</li>
      </ol>
      <br />
      <font size="+2">Adventures</font>
      <ol>
        <li>Rate adventure quality (1-10)</li>
        <li>
          Rate adventure difficulty (Easy, Normal, Hard, Brutal, Impossible)
        </li>
        <li>Give reviews on adventure</li>
        <li>Comment on adventure</li>
      </ol>
      <br />
      <font size="+2">Users</font>
      <ol>
        <li>Follow creators</li>
        <li>Creator page</li>
        <li>Linking a creator to a user</li>
        <li>Share user profile page</li>
      </ol>
    </div>
  );
}

export default HomePage;
