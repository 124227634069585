// PlaylistTable.js
import React, { useState } from "react";
import { useModal } from "../ModalManager";
import NewPlaylist from "./NewPlaylist";
import Tables from "../Components/Tables/Tables";
import Buttons from "../Components/Buttons/Buttons";
import Popups from "../Components/Popups/Popups";

export default function PlaylistTable({ playlistArray, functionOnSelect }) {
  const { openModal } = useModal();
  const propertyNames = ["name", "adventuresCount", "created"];

  console.log("playlistArray", playlistArray);
  const selectPlaylist = (playlist) => {
    console.log("Selected Playlist", playlist);
    if (functionOnSelect) functionOnSelect(playlist);
  };

  const actionButtons = [
    (props) => (
      <Buttons.Round
        key={props.index}
        text="✓"
        onClick={() => selectPlaylist(props.row)}
      />
    ),
    // ... Add more buttons here if necessary
  ];

  return (
    <div className="adventure-table-window">
      {/* ...rest of the JSX */}
      <Buttons.Glowing
        text="Create Playlist"
        onClick={() =>
          openModal(
            <Popups.Form title="Create New Playlist" form={<NewPlaylist />} />
          )
        }
      />
      <Tables.General
        objectArray={playlistArray}
        propertyNames={propertyNames}
        elements={actionButtons}
      />
    </div>
  );
}
