import { getAuth, signOut } from "firebase/auth";
import Managers from "../Managers";
import Pages from "../Pages/Pages";

const logoutUser = async () => {
  const auth = getAuth();
  try {
    await signOut(auth);

    try {
      Pages.navigate(Pages.names[0]);
    } catch (error) {
      console.error("Pages.navigate(Pages.names[0]) failed in logoutUser.js.");
    }
  } catch (error) {
    console.error("Error signing out user: ", error);
  }
};

export default logoutUser;
